<template>
  <CCard
    accent-color="primary"
    style='margin-bottom: 15px'
  >
    <CCardHeader>
      <CRow>
        <template v-if='!trigger'>
          <CCol sm='11'>
            <strong>Trigger</strong>
          </CCol>
          <CCol sm='1'>
            <CButton
              v-if="!trigger"
              color="primary"
              size="sm"
              @click="buildTrigger"
            >
              <CIcon :icon="icons.cilPlus" />
            </CButton>
          </CCol>
        </template>

        <template v-else>
          <CCol sm='10'>
            <strong>Trigger</strong>
          </CCol>
          <CCol sm='1'>
            <CFormSwitch
              v-model='trigger.enabled'
              class="mr-2 align-middle"
              size="xl"
              type="checkbox"
              data-on="On"
              data-off="Off"
            />
          </CCol>

          <CCol sm='1'>
            <CButton
              color="danger"
              size="sm"
              @click="toggleDeleteConfimation"
            >
              <CIcon :icon="icons.cilTrash" />
            </CButton>
          </CCol>
        </template>
      </CRow>
    </CCardHeader>

    <CCardBody v-if="trigger">
      <CRow style='margin-bottom: 10px;'>
        <CCol sm="12">
          <CFormSelect
            v-model='trigger.frequency.unit'
            label="Select a unit of time"
            :options="frequency_options.units"
            @change="(e) => onUnitChange(e.target.value)"
          />
        </CCol>
      </CRow>

      <label>Select the required interval:</label>
      <template v-if="trigger.frequency.unit === 'day'">
        <CRow>
          <CCol sm="12">
            <CFormSelect
              v-model='trigger.frequency.time'
              label="Every day at"
              append="UTC"
              :options="frequency_options.time"
              @change="(e) => onTimeChange(e.target.value)"
            />
          </CCol>
        </CRow>
      </template>
      <template v-else-if="trigger.frequency.unit === 'week'">
        <CRow>
          <CCol sm="6">
            <CFormSelect
              v-model='trigger.frequency.weekdays'
              label="Every"
              :options="frequency_options.weekdays"
            />
          </CCol>

          <CCol sm="6">
            <CFormSelect
              v-model='trigger.frequency.time'
              label="at"
              append="UTC"
              :options="frequency_options.time"
              @change="(e) => onTimeChange(e.target.value)"
            />
          </CCol>
        </CRow>
      </template>
      <template v-else-if="trigger.frequency.unit === 'month'">
        <CRow>
          <CCol sm="6">
            <CFormSelect
              v-model:value='trigger.frequency.monthdays'
              label="On day"
              :options="frequency_options.days"
              @change="(e) => onMonthdaysChange(e.target.value)"
            />
          </CCol>

          <CCol sm="6">
            <CFormSelect
              v-model='trigger.frequency.time'
              label="at"
              append="UTC"
              :options="frequency_options.time"
              @change="(e) => onTimeChange(e.target.value)"
            />
          </CCol>
        </CRow>
      </template>

      <DeleteConfirmationModal
        :opened="delete_confirmation_opened"
        :confirmCb='confirmDelete'
        :closeCb='closeDeleteModal'
      >
        <div class="text-center">
          Are you sure you want to delete reports' trigger?
        </div>
      </DeleteConfirmationModal>
    </CCardBody>
  </CCard>
</template>

<script>
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CFormSwitch,
  CRow
} from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import { inject } from 'vue'
import DeleteConfirmationModal from '../Shared/DeleteConfirmationModal/DeleteConfirmationModal'

export default {
  props: ['configuration'],

  emits: ['change'],

  components: {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CIcon,
    CFormSelect,
    CFormSwitch,
    CRow,
    DeleteConfirmationModal
  },

  watch: {
    trigger: {
      handler: function(value) {
        this.$emit('change', 'trigger', value)
      },
      deep: true
    }
  },

  data() {
    return {
      trigger: null,
      delete_confirmation_opened: false,
      frequency_options: {
        units: [
          { label: 'Day', value: 'day' },
          { label: 'Week', value: 'week' },
          { label: 'Month', value: 'month' }
        ],
        weekdays: [
          { label: 'Monday', value: 1 },
          { label: 'Tuesday', value: 2 },
          { label: 'Wednesday', value: 3 },
          { label: 'Thursday', value: 4 },
          { label: 'Friday', value: 5 },
          { label: 'Saturday', value: 6 },
          { label: 'Sunday', value: 0 }
        ],
        days: this.monthdayOptions(),
        time: this.timeOptions()
      }
    }
  },

  created() {
    this.icons = inject('icons')
    this.trigger = this.configuration && Object.assign({}, this.configuration)
  },

  methods: {
    buildTrigger() {
      if (!this.trigger) {
        this.trigger = {
          enabled: true,
          frequency: {
            unit: this.frequency_options.units[0].value
          }
        }
        this.onUnitChange(this.trigger.unit)
      }
    },

    deleteTrigger() {
      this.trigger = null
    },

    onUnitChange(value) {
      this.trigger.frequency.time = this.frequency_options.time[0]
      this.onTimeChange(this.trigger.frequency.time)

      this.trigger.frequency.monthdays = value === 'month' ? this.frequency_options.days[0] : null
      this.trigger.frequency.weekdays = value === 'week' ? this.frequency_options.weekdays[0].value : null
    },

    onTimeChange(value) {
      if (!this.trigger) return
      const arr = value.split(' ')
      const t = arr[0].split(':').map(i => parseInt(i))

      switch (arr[1]) {
        case 'AM':
          this.trigger.frequency.hours = t[0] === 12 ? 0 : t[0]
          break
        case 'PM':
          this.trigger.frequency.hours = t[0] === 12 ? t[0] : t[0] + 12
          break
      }
      this.trigger.frequency.minutes = t[1]
    },

    onMonthdaysChange(value) {
      this.trigger.frequency.monthdays = value
    },

    toggleDeleteConfimation() {
      this.delete_confirmation_opened = !this.delete_confirmation_opened
    },

    confirmDelete() {
      this.deleteTrigger()
      this.toggleDeleteConfimation()
    },

    closeDeleteModal(status, evt, accept) {
      this.toggleDeleteConfimation()
    },

    monthdayOptions() {
      const options = Array.from({ length: 31 }, (_, i) => {
        return { label: i + 1, value: i + 1 }
      })
      options.push({ label: 'Last', value: -1 })
      return options
    },

    timeOptions() {
      const x = 30 // minutes interval
      const times = [] // time array
      let tt = 0 // start time
      const ap = ['AM', 'PM'] // AM-PM

      for (let i = 0; tt < 24 * 60; i++) {
        const hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
        const mm = (tt % 60) // getting minutes of the hour in 0-55 format
        times[i] = `${('0' + ((hh === 0 || hh === 12) ? 12 : hh % 12)).slice(-2)}:${('0' + mm).slice(-2)} ${ap[Math.floor(hh / 12)]}`
        tt = tt + x
      }

      return times
    }
  }
}
</script>
