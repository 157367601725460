<template>
<VeeForm
  v-slot="{ meta }"
  :validateOnInput='true'
  :validateOnChange='true'
  validateOnMount
>
  <span class='ada-hidden'>{{ validateForm(meta) }}</span>

  <CCard
    accent-color="primary"
    style='margin-bottom: 15px'
  >
    <CCardHeader>
      <strong>SFTP</strong>
    </CCardHeader>

    <CCardBody>
      <!-- Host -->
      <Field
        v-slot="{ field, meta }"
        v-model='sftp.host'
        rules='required'
        name='host'
      >
        <CRow style='margin-bottom: 10px'>
          <CCol sm='3'>
            <label
              for="host"
              class="form-label"
            >
              Host
            </label>
          </CCol>

          <CCol sm='9'>
            <input
              v-bind='field'
              id='host'
              type='text'
              class='form-control'
              :class="validationClass(meta)"
            >
          </CCol>
        </CRow>
      </Field>

      <!-- Source Folder -->
      <Field
        v-slot="{ field, meta }"
        v-model='sftp.source_folder'
        rules='required'
        name='source_folder'
      >
        <CRow style='margin-bottom: 10px'>
          <CCol sm='3'>
            <label
              for="host"
              class="form-label"
            >
              Source Folder
            </label>
          </CCol>

          <CCol sm='9'>
            <input
              id='source_folder'
              type='text'
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
            >
          </CCol>
        </CRow>
      </Field>

      <!-- Auth methods -->
      <CRow
        class="form-group"
        style='margin-bottom: 10px'
      >
        <CCol
          tag="label"
          sm="3"
          class="col-form-label"
        >
          Auth methods
        </CCol>

        <CCol sm="9">
          <div
            v-for="method in auth_methods"
            :key='method'
            class="form-check"
          >
            <input
              :id="method"
              class="form-check-input"
              :class="checkBoxValidationClass()"
              type="checkbox"
              :value='method'
              :checked="isAuthMethodEnabled(method)"
              @change="onAuthMethodEnabledChange"
            >

            <label
              class="form-check-label"
              :for="method"
            >
              {{ method }}
            </label>
          </div>
        </CCol>
      </CRow>

      <!-- SFTP -->
      <Field
        v-slot="{ field, meta }"
        v-model='sftp.user'
        rules='required'
        name='user'
      >
        <CRow style='margin-bottom: 10px'>
          <CCol sm='3'>
            <label
              for="user"
              class="form-label"
            >
              User
            </label>
          </CCol>

          <CCol sm='9'>
            <input
              id='user'
              type='text'
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
            >
          </CCol>
        </CRow>
      </Field>

      <!-- Password -->
      <Field
        v-if="isAuthMethodEnabled('password')"
        v-slot="{ field, meta }"
        v-model='sftp.password'
        :rules='password_validation_rules'
        name='password'
      >
        <CRow style='margin-bottom: 10px'>
          <CCol sm='3'>
            <label
              for="password"
              class="form-label"
            >
              Password
            </label>
          </CCol>

          <CCol sm='9'>
            <input
              id='password'
              :type="password_field_type"
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
            >
          </CCol>
        </CRow>
      </Field>

      <!-- Public Key -->
      <Field
        v-if="isAuthMethodEnabled('publickey')"
        v-slot="{ field, meta }"
        v-model='sftp.key_data[0]'
        :rules='publickey_validation_rules'
        name='key_data[]'
      >
        <CRow style='margin-bottom: 10px'>
          <CCol sm='3'>
            <label
              for="key_data"
              class="form-label"
            >
              Key Data
            </label>
          </CCol>

          <CCol sm='9'>
            <textarea
              id='key_data'
              rows='15'
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
              @input="(e) => { sftp.key_data.splice(0, 1, adjustNewlines(e)) }"
            />
          </CCol>
        </CRow>
      </Field>

      <!-- Proxy -->
      <CRow class="form-group">
        <CCol
          tag="label"
          sm="3"
          class="col-form-label"
        >
          Proxy
        </CCol>

        <CCol
          sm="9"
          style='padding-top: 5px'
        >
          <CFormCheck v-model="sftp.proxy" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</VeeForm>
</template>

<script>
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormCheck,
  CLink,
  CRow,
  CTextarea
} from '@coreui/vue'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import { required } from '@vee-validate/rules'

export default {
  props: ['configuration'],

  emits: [
    'change',
    'update-validity'
  ],

  components: {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormCheck,
    CRow,
    VeeForm,
    Field,
  },

  watch: {
    sftp: {
      handler: function(value) {
        this.$emit('change', 'sftp', value)
      },
      deep: true
    },
    'form_valid': function(value) {
      const valid = value && this.auth_valid
      this.$emit('update-validity', 'sftp', valid)
    },
    'auth_valid': function(value) {
      const valid = value && this.form_valid
      this.$emit('update-validity', 'sftp', valid)
    }
  },

  data() {
    return {
      sftp: {},
      auth_methods: [
        'password',
        'keyboardinteractive',
        'publickey'
      ],
      password_field_type: 'password',
      form_valid: true,
      auth_valid: true
    }
  },

  created() {
    this.sftp = this.configuration && Object.assign({}, this.configuration)

    // validation rules
    defineRule('required', required);
  },

  mounted() {
    this.$emit('update-validity', 'sftp', this.form_valid && this.auth_valid)
  },

  computed: {
    enabled_auth_methods() {
      return this.sftp.auth_methods || []
    },

    auth_methods_empty() {
      return !this.enabled_auth_methods.length
    },

    password_validation_rules() {
      return this.isAuthMethodEnabled('password') ? 'required' : ''
    },

    publickey_validation_rules() {
      return this.isAuthMethodEnabled('publickey') ? 'required' : ''
    }
  },

  methods: {
    isAuthMethodEnabled(method) {
      return this.enabled_auth_methods.includes(method)
    },

    onAuthMethodEnabledChange({ target }) {
      const method = target.value
      if (target.checked) {
        if (!this.sftp.auth_methods) {
          this.sftp.auth_methods = []
        }
        this.sftp.auth_methods.push(method)
        if (method === 'publickey') {
          this.sftp.key_data = ['']
        }
      } else {
        this.sftp.auth_methods = this.sftp.auth_methods.filter(m => m !== method)
        if (method === 'password') {
          delete this.sftp.password
        }
        if (method === 'publickey') {
          delete this.sftp.key_data
        }
      }
    },

    validationClass(meta) {
      return {
        'is-invalid': meta.validated && !meta.valid
      }
    },

    checkBoxValidationClass() {
      this.auth_valid = !this.auth_methods_empty
      return {
        'is-invalid': this.auth_methods_empty
      }
    },

    validateForm(meta) {
      this.form_valid = meta.valid
    },

    togglePasswordVisibility() {
      this.password_field_type = this.password_field_type === 'password' ? 'text' : 'password'
    },

    adjustNewlines(e) {
      e.target.value = e.target.value.replace(/\\n/g, '\n')
      return e.target.value
    }
  }
}
</script>

<style lang='sass' scoped>
.invalid-feedback
  display: block
</style>
