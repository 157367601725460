<template>
<div>
  <div class="mb-2 d-flex flex-row-reverse">
    <CButton
      color="primary"
      size="lg"
      @click="openModal(null)"
    >
      <CIcon :icon="icons.cilUserPlus" />
    </CButton>
  </div>

  <CAlert
    dismissible
    class="d-flex flex-row align-items-center"
    color="primary"
  >
    <CIcon :icon="icons.cilWarning" />
    <div>Search is performed when you press Enter.</div>
  </CAlert>

  <CTable
    hover
    striped
    bordered
    align="middle"
  >
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col">
          Email
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "email")'
          >
        </CTableHeaderCell>

        <CTableHeaderCell scope="col">
          First Name
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "first_name")'
          >
        </CTableHeaderCell>

        <CTableHeaderCell scope="col">
          Last Name
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "last_name")'
          >
        </CTableHeaderCell>

        <CTableHeaderCell scope="col">
          Role
        </CTableHeaderCell>

        <CTableHeaderCell scope="col">
          Status
        </CTableHeaderCell>

        <CTableHeaderCell
          scope="col"
          style='width: 4%'
        />
      </CTableRow>
    </CTableHead>

    <CTableBody>
      <CTableRow
        v-for='(user, index) in users'
        :key='index'
        @click.prevent='openModal(user)'
      >
        <CTableDataCell>{{ user.email }}</CTableDataCell>
        <CTableDataCell>{{ user.first_name }}</CTableDataCell>
        <CTableDataCell>{{ user.last_name }}</CTableDataCell>

        <CTableDataCell>
          <div
            v-for="role in user.roles"
            :key="role.id"
          >
            {{ role.name }}
          </div>
        </CTableDataCell>

        <CTableDataCell class='text-center'>
          <CBadge
            v-if="user.enabled"
            color="success"
          >
            Enabled
          </CBadge>

          <CBadge
            v-else
            color="danger"
          >
            Disabled
          </CBadge>
        </CTableDataCell>

        <CTableDataCell>
          <CButton
            v-if="!isCurrentAdmin(user)"
            size="sm"
            @click.stop="confirmUserDelete(user)"
          >
            <CIcon :content="icons.cilTrash" />
          </CButton>
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>

  <CPagination
    v-if='total_pages > 1'
    align='center'
  >
    <CPaginationItem
      v-for='(page, index) in pages'
      :key='index'
      href="#"
      :active='page_number == index + 1'
      @click.prevent='changePage(index)'
    >
      {{ page + 1 }}
    </CPaginationItem>
  </CPagination>

  <UserModal
    :opened="user_modal_opened"
    :user="current_user"
    :self="isCurrentAdmin(current_user)"
    :roles="roles"
    :api_client="api_client"
    @modal-closed="closeModal"
    @user-created="fetchUsers"
    @user-updated="updateCurrentUser"
  />

  <DeleteConfirmationModal
    :opened="delete_modal_opened"
    :confirmCb='confirmDelete'
    :closeCb='closeDeleteModal'
  >
    <div
      v-if="current_user"
      class="text-center"
    >
      Are you sure you want to delete
      <span class="font-weight-bold">{{ current_user.email }}</span> admin user?
    </div>
  </DeleteConfirmationModal>

  <CSpinner v-if='!users_loaded' />
</div>
</template>

<script>
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody
} from '@coreui/vue'

import {
  CAlert,
  CBadge,
  CButton,
  CPagination,
  CPaginationItem,
  CSpinner
} from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'

import UserModal from './UserModal/UserModal'
import DeleteConfirmationModal from '../Shared/DeleteConfirmationModal/DeleteConfirmationModal'
import { inject } from 'vue'

export default {
  props: [
    'api_client',
    'current_admin_id'
  ],

  components: {
    CAlert,
    CBadge,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CIcon,
    CPagination,
    CPaginationItem,
    CSpinner,
    UserModal,
    DeleteConfirmationModal
  },

  data() {
    return {
      users: [],
      roles: [],
      search_query: {},
      page_number: 1,
      total_pages: 0,
      users_loaded: false,
      current_user: null,
      user_modal_opened: false,
      delete_modal_opened: false
    }
  },

  created() {
    this.icons = inject('icons')
    this.fetchAdminRoles()
    this.fetchUsers()
  },

  computed: {
    current_user_index() {
      if (this.current_user) {
        return this.users.indexOf(this.current_user)
      } else {
        return null
      }
    },

    pages() {
      return [...Array(this.total_pages).keys()]
    }
  },

  methods: {
    fetchAdminRoles() {
      this.api_client.findAll('admin_roles')
        .then(({ data }) => {
          this.roles = data
        })
    },

    fetchUsers() {
      this.users_loaded = false

      this.api_client.findAll('admin_users', {
        page: {
          number: this.page_number
        },
        query: this.search_query
      }).then((data) => {
        this.users = data.data
        this.total_pages = data.meta.total_pages
        this.users_loaded = true
      })
    },

    changePage(index) {
      this.page_number = index + 1;
      this.fetchUsers()
    },

    searchUsersBy(e, param) {
      // reject empty string params
      // if (!e.target.value.length) return false
      // Object.keys(params).forEach((k) => { (params[k].length === 0) && delete params[k] })
      const params = {
        [param]: e.target.value
      }
      this.search_query = params
      this.page_number = 1
      this.fetchUsers()
    },

    openModal(user) {
      this.current_user = user
      this.user_modal_opened = true
    },

    closeModal() {
      this.user_modal_opened = false
      this.resetCurrentUser()
    },

    updateCurrentUser(user) {
      this.users.splice(this.current_user_index, 1, user)
      this.current_user = user
    },

    resetCurrentUser() {
      this.current_user = null
    },

    isCurrentAdmin(user) {
      return user && user.id === this.current_admin_id
    },

    confirmUserDelete(user) {
      this.current_user = user
      this.delete_modal_opened = true
    },

    confirmDelete() {
      this.api_client.destroy('admin_users', this.current_user.id)
        .then(() => {
          this.users.splice(this.current_user_index, 1)
        })
        .catch((_error) => {
          Tbk.alerts.new('Sorry, but something went wrong', 'error')
        })
        .finally(() => {
          this.resetCurrentUser()
        })
      this.delete_modal_opened = false
    },

    closeDeleteModal() {
      this.resetCurrentUser()
      this.delete_modal_opened = false
    }
  }
}
</script>

<style lang='sass'>
@import './AdminUsers'
</style>
