<template>
  <EmbedChooser
    v-if="active_tool === 'embed_generator'"
    :api_client='api_client'
    :admin_api_url='admin_api_url'
    :api_url='api_url'
    :embed_loader_url='params.embed_loader_url'
  />

  <Users
    v-else-if="active_tool === 'users'"
    :api_client='api_client'
    :is_admin_root='params.is_admin_root'
  />

  <AdminUsers
    v-else-if="active_tool === 'admin_users'"
    :api_client='api_client'
    :current_admin_id='params.admin_user_id'
  />

  <ReportsConfiguration
    v-else-if="active_tool === 'reports'"
    :api_client='api_client'
    :properties="params.properties"
  />

  <SamlConfigurations
    v-else-if="active_tool === 'saml_configurations'"
    :api_client='api_client'
  />

  <AcademyPartners
    v-else-if="active_tool === 'academy_partners'"
    :api_client='api_client'
    :api_url='api_url'
    :admin_api_url='admin_api_url'
  />
</template>

<script>
import { inject } from 'vue'

import EmbedChooser from './EmbedChooser/EmbedChooser'
import Users from './Users/AllUsers'
import AdminUsers from './AdminUsers/AdminUsers'
import AcademyPartners from './AcademyPartners/AcademyPartners.vue'
import ReportsConfiguration from './ReportsConfiguration/ReportsConfiguration'
import SamlConfigurations from './SamlConfigurations/SamlConfigurations'
import apiClient from '../api'

export default {

  components: {
    AdminUsers,
    AcademyPartners,
    Users,
    EmbedChooser,
    ReportsConfiguration,
    SamlConfigurations
  },

  data() {
    return {
      api_client: null,
      jwt: null,
      active_tool: null,
      api_url: null,
      admin_api_url: null,
      params: null
    }
  },

  created() {
    this.options = inject('options')
    this.configuration = inject('configuration')

    this.jwt = this.options.jwt
    this.active_tool = this.options.active_tool
    this.api_url = this.options.api_url
    this.admin_api_url = this.options.admin_api_url
    this.params = this.options.params

    apiClient.setUrl(this.admin_api_url)
    apiClient.setToken(this.jwt)
    this.api_client = apiClient
  }
}
</script>

<style lang="sass">
@import "../styles/admin-tools"
</style>
