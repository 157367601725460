import { createApp } from 'vue'
import { setUpI18n } from 'tbk-utils/i18n'
import _ from 'lodash'
import coreui from '@coreui/coreui/dist/js/coreui.bundle.min'

import AdminToolsApp from './components/app.vue'

import {
  cilWarning,
  cilPencil,
  cilUserPlus,
  cilPlus,
  cilTrash,
  cilLowVision,
  cilUser,
  cilChevronBottom,
  cilChevronRight,
  cilSwapVertical,
  cilX
} from '@coreui/icons'

window.initAdminToolsApp = function(options) {
  const i18n = setUpI18n(options.translations)
  if (options.translations) {
    delete options.translations
  }

  window._ = _
  window.coreui = coreui

  const icons = {
    cilWarning,
    cilPencil,
    cilUserPlus,
    cilPlus,
    cilTrash,
    cilLowVision,
    cilUser,
    cilChevronBottom,
    cilChevronRight,
    cilSwapVertical,
    cilX
  }

  const app = createApp(AdminToolsApp)
  app.use(i18n)

  // provide options
  app.provide('options', options)
  app.provide('configuration', options.configuration)
  app.provide('icons', icons)

  // finally mount the app
  app.mount(options.bind_element)
}
