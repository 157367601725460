// Based on API::Admin::V1::AcademyPartnerSerializer
export default {
  // Attributes
  id: null,
  subdomain: null,
  fqdn_url: null,
  auth: null,
  academy: null,
  partner: null,
  theme_identifier: null,
  theming: null,
  fqdn_combinations: null,
}
