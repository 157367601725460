<template>
<div class="academy-partner-info-modal">
  <CSpinner
    v-if='!(is_courses_loaded && is_global_slides_loaded)'
  />

  <div v-else>
    <!-- General Info -->
    <div class="row gutters-sm">
      <div class="col-md-8">
        <div class="card-box mb-4 mt-3">
          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0">
                URL
              </h6>
            </div>

            <div class="col-sm-9">
              <a
                :href='academy_partner.fqdn_url'
                target='_blank'
              >
                {{ academy_partner.fqdn_url }}
              </a>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-3">
              <h6 class="mb-0">
                ID
              </h6>
            </div>

            <div class="col-sm-9">
              {{ academy_partner.id }}
            </div>
          </div>

          <hr>
        </div>
      </div>
    </div>
    <!-- General Info End -->

    <!-- Tabs -->
    <CNav variant="tabs">
      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tab_panel_active_key === 1"
          @click="() => {tab_panel_active_key = 1}"
        >
          Courses
        </CNavLink>
      </CNavItem>

      <CNavItem>
        <CNavLink
          href="javascript:void(0);"
          :active="tab_panel_active_key === 2"
          @click="() => {tab_panel_active_key = 2}"
        >
          Global Slides
        </CNavLink>
      </CNavItem>
    </CNav>

    <CTabContent>
      <CTabPane
        role="tabpanel"
        :visible="tab_panel_active_key === 1"
      >
        <!-- Courses List -->
        <div
          v-if='!courses.length'
          class='empty-list'
        >
          No Courses
        </div>

        <CAccordion>
          <CAccordionItem
            v-for='(course, index) in courses'
            :key='course.identifier'
            :item-key='index'
          >
            <CAccordionHeader>
              <div
                class="row"
                style='width: 100%'
              >
                <div class="col-md-10">
                  <a
                    :href='urlForCourse(course)'
                    target='_blank'
                    @click.stop='urlClick($event, course)'
                  >
                    {{ course.title }}
                  </a>
                </div>

                <div class="col-md-2 text-right">
                  <CBadge
                    v-if='course.published'
                    color="info"
                  >
                    Published
                  </CBadge>

                  <CBadge
                    v-if='!course.published'
                    color="secondary"
                  >
                    Unpublished
                  </CBadge>
                </div>
              </div>
            </CAccordionHeader>

            <CAccordionBody>
              <div
                v-for='lesson in course.lessons'
                :key="lesson.identifier"
                class="card card--lesson mb-0"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <a
                        :href='urlForLesson(course, lesson)'
                        target='_blank'
                        @click.stop='$event.preventDefault()'
                      >
                        {{ lesson.title }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <!-- Courses List End -->
      </CTabPane>

      <CTabPane
        role="tabpanel"
        :visible="tab_panel_active_key === 2"
      >
        <!-- Global Slides List -->
        <div
          v-if='!global_slides.length'
          class='empty-list'
        >
          No Global Slides
        </div>

        <div
          id="accordion-global-slides"
          class="accordion col-12"
        >
          <div
            v-for='slide in global_slides'
            :key="slide.identifier"
            class="card card--global-slide mb-0"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <a
                    :href='urlForGlobalSlide(slide)'
                    target='_blank'
                    @click.stop='$event.preventDefault()'
                  >
                    {{ slide.identifier }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CTabPane>
    </CTabContent>
  </div>
</div>
</template>

<script>
import {
  CSpinner,
  CNav,
  CNavItem,
  CNavLink,
  CBadge,
  CTabContent,
  CTabPane,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody
} from '@coreui/vue'

export default {
  props: ['api_client', 'academy_partner', 'api_url', 'admin_api_url'],

  components: {
    CSpinner,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CBadge,
    CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionBody
  },

  data() {
    return {
      is_global_slides_loaded: false,
      is_courses_loaded: false,

      courses: null,

      global_slides: null,

      tab_panel_active_key: 1,

      supported_activity_types: [
        'activity_v1_articles',
        'activity_v1_audios',
        'activity_v1_basic_contents',
        'activity_v1_discussions',
        'activity_v1_fullscreens',
        'activity_v1_intention_setters',
        'activity_v1_journals',
        'activity_v1_paired_comparisons',
        'activity_v1_polls',
        'activity_v1_quizzes',
        'activity_v1_resources',
        'activity_v1_self_assessments',
        'activity_v1_videos',
        'activity_v1_video_with_instructions',
        'activity_v2_self_assessments'
      ]
    }
  },

  created() {
    this.api_client.setUrl(this.api_url)
    this.api_client.setSubdomain(this.academy_partner.subdomain)

    this.fetchCourses()
    this.fetchGlobalSlides()

    setTimeout(() => {
      delete this.api_client.headers['X-TBK-AcademyHost']
      this.api_client.setUrl(this.admin_api_url)
    }, 1000)
  },

  methods: {
    fetchCourses() {
      this.is_courses_loaded = false
      this.api_client.findAll('courses', {
        include: 'lessons',
        fields: {
          courses: ['identifier', 'title', 'lessons', 'published'],
          lessons: ['identifier', 'title']
        }
      }).then(
        function({ data }) {
          this.courses = data
          this.is_courses_loaded = true
        }.bind(this)
      )
    },

    fetchGlobalSlides() {
      this.is_global_slides_loaded = false
      this.api_client.findAll('global_slides', { include: 'activity' }).then(
        function({ data }) {
          const slides = []
          data.forEach((e) => {
            if (this.supported_activity_types.filter((t) => t === e.activity.type).length) {
              slides.push(e)
            }
          })
          this.global_slides = slides
          this.is_global_slides_loaded = true
        }.bind(this)
      )
    },

    urlClick(e, course) {
      e.preventDefault()
      window.open(
        this.urlForCourse(course),
        '_blank'
      )
      return false
    },

    urlForCourse(course) {
      return `${this.academy_partner.fqdn_url}/landing/courses/${course.identifier}`
    },

    urlForLesson(course, lesson) {
      return `${this.academy_partner.fqdn_url}/landing/j/courses/${course.identifier}/lessons/${lesson.identifier}`
    },

    urlForGlobalSlide(slide) {
      return `${this.academy_partner.fqdn_url}/landing/activities/${slide.identifier}`
    }
  }
}
</script>

<style lang='sass' scoped>
.accordion
  margin: 0
  padding: 0
  top: -1px

  a
    font-size: 14px

  .card--course,
  .card--lesson,
  .card--global-slide
    border-radius: 0
    margin-top: -1px

    a
      color: #3c4b64

  .card--lesson .card-body,
  .card--global-slide .card-body
    padding: .75rem 1.25rem

  .lessons-list
    padding: 16px

.accordion-item
  border-radius: 0

  &:first-of-type
    border-top: 0

.empty-list
  padding: .75rem 1.25rem 0

.academy-partner-info-modal a
  text-decoration: none
  color: #3c4b64

  &:hover
    text-decoration: underline

</style>
