<template>
  <CModal
    alignment='center'
    :visible="opened"
    size="xl"
    :closeOnBackdrop="false"
    @close="closeModal"
  >
    <CModalHeader>
      <CIcon
        size="lg"
        :icon="header_icon"
      />
    </CModalHeader>

    <CModalBody>
      <UserInfo
        v-if="opened"
        :opened="opened"
        :user="user_data"
        :self="self"
        :roles="roles"
        :api_client="api_client"
        @update-validity="updateValidity"
      />
    </CModalBody>

    <CModalFooter>
      <CButton
        color="primary"
        :disabled="submit_disabled"
        @click="saveUser"
      >
        Save
      </CButton>
      <CButton
        color="secondary"
        @click="closeModal"
      >
        Close
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter
} from '@coreui/vue'

import { CIcon } from '@coreui/icons-vue'

import { cilPencil, cilPlus } from '@coreui/icons'
import UserInfo from './UserInfo'
import { inject } from 'vue'

export default {
  components: {
    CButton,
    CIcon,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    UserInfo
  },

  props: [
    'opened',
    'api_client',
    'user',
    'self',
    'roles'
  ],

  emits: [
    'user-created',
    'user-updated',
    'modal-closed'
  ],

  watch: {
    opened: function() {
      this.user_data = this.user ? JSON.parse(JSON.stringify(this.user)) : {}
    }
  },

  data() {
    return {
      user_data: {},
      user_valid: false,
      submit_in_progress: false
    }
  },

  created() {
    this.icons = inject('icons')
  },

  computed: {
    user_changed() {
      return this.user ? !_.isEqual(this.user, this.user_data) : Object.keys(this.user_data).length > 0
    },

    header_icon() {
      return this.user ? this.icons.cilPlus : this.icons.cilPlus
    },

    submit_disabled() {
      return !(this.user_changed && this.user_valid) || this.submit_in_progress
    }
  },

  methods: {
    saveUser() {
      this.submit_in_progress = true
      const callback = () => { this.submit_in_progress = false }

      if (this.user) {
        this._updateUser(callback)
      } else {
        this._createUser(callback)
      }
    },

    updateValidity(valid) {
      this.user_valid = valid
    },

    closeModal() {
      this.$emit('modal-closed')
    },

    _createUser(callback) {
      this.api_client.create('admin_user', this.user_data)
        .then(({ data }) => {
          this.$emit('user-created', data)
          delete this.user_data.password
          Tbk.alerts.new('Admin user has been successfully created', 'success')
          this.closeModal()
        })
        .catch((error) => {
          this._onSaveError(error)
        })
        .finally(() => {
          callback()
        })
    },

    _updateUser(callback) {
      this.api_client.update('admin_user', this.user_data)
        .then(({ data }) => {
          this.$emit('user-updated', data)
          delete this.user_data.password
          Tbk.alerts.new('Admin user has been successfully updated', 'success')
        })
        .catch((error) => {
          this._onSaveError(error)
        })
        .finally(() => {
          callback()
        })
    },

    _onSaveError(error) {
      if (error.admin_user) {
        Tbk.alerts.new(`${error.admin_user.detail}`, 'error')
      } else {
        Tbk.alerts.new('Sorry, but something went wrong', 'error')
      }
    }
  }
}
</script>
