<template>
  <CCard
    accent-color="primary"
    style='margin-bottom: 15px; margin-top: 15px;'
  >
    <CCardHeader>
      <strong>Reports</strong>
    </CCardHeader>

    <CCardBody>
      <CRow>
        <CCol md="7">
          <label class="mb-4">Select reports you want to enable</label>
          <CCard
            v-for="(label, report) in supported_reports"
            :key="report"
            accent-color="secondary"
            class="mb-2"
          >
            <CCardHeader>
              <CRow>
                <CCol md='11'>
                  <div class="form-check">
                    <input
                      :id="report"
                      class="form-check-input"
                      type="checkbox"
                      :value="report"
                      :checked="isReportEnabled(report)"
                      @change="onReportEnabledChange"
                    >
                    <label
                      class="form-check-label"
                      :for="report"
                    >
                      {{ label }}
                    </label>
                  </div>
                </CCol>

                <CCol
                  md='1'
                  style='text-align: right'
                >
                  <div class="card-header-actions">
                    <CLink
                      class="card-header-action btn-minimize"
                      @click="toggleReportConfiguration(report)"
                    >
                      <CIcon
                        v-if='collapse_state[report]'
                        :icon="icons.cilChevronBottom"
                      />

                      <CIcon
                        v-if='!collapse_state[report]'
                        :icon="icons.cilChevronRight"
                      />
                    </CLink>
                  </div>
                </CCol>
              </CRow>
            </CCardHeader>

            <CCollapse :visible="!!collapse_state[report]">
              <CCardBody
                class="report-configuration"
                :class="{'disabled' : !isReportEnabled(report)}"
              >
                <template v-if="report === 'all_raw_activities'">
                  <div
                    v-for="type in supported_activity_types"
                    :key="type"
                    class="form-check"
                  >
                    <input
                      :id="type"
                      class="form-check-input"
                      type="checkbox"
                      :value="type"
                      :checked="isActivityTypeEnabled(type)"
                      @change="onActivityTypeEnabledChange"
                    >
                    <label
                      class="form-check-label"
                      :for="type"
                    >
                      {{ type }}
                    </label>
                  </div>
                </template>

                <template v-else>
                  <table class="table table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          Column Name
                        </th>
                        <th scope="col">
                          Value
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>

                    <draggable
                      tag="tbody"
                      :list="reportAttributes(report)"
                      item-key="id"
                      @change="syncAttributes(report)"
                    >
                      <template #item="{element}">
                        <tr class="move">
                          <td scope="row">
                            <CIcon :icon="icons.cilSwapVertical" />
                          </td>
                          <template v-if="isEditableAttribute(report, element.attr_name)">
                            <td>
                              <CFormInput
                                id='attr_name'
                                v-model="element.attr_name"
                                class="mb-0"
                                size='sm'
                                @input="syncAttributes(report)"
                              />
                            </td>
                            <td>
                              <CFormInput
                                id='attr_value'
                                v-model="element.attr_value"
                                class="mb-0"
                                size='sm'
                                @input="syncAttributes(report)"
                              />
                            </td>
                            <td>
                              <CLink
                                class="btn p-0"
                                @click="deleteAttribute(report, element)"
                              >
                                <CIcon :icon="icons.cilX" />
                              </CLink>
                            </td>
                          </template>

                          <template v-else>
                            <td>{{ element.attr_name }}</td>
                            <td>{{ element.attr_value }}</td>
                            <td />
                          </template>
                        </tr>
                      </template>
                    </draggable>
                  </table>

                  <CLink
                    v-if="root_access"
                    class="btn"
                    @click="addAttribute(report)"
                  >
                    <CIcon :icon="icons.cilPlus" />
                  </CLink>
                </template>
              </CCardBody>
            </CCollapse>
          </CCard>
        </CCol>

        <div class="d-lg-none d-md-none w-100">
          <hr class="my-4">
        </div>

        <CCol md="5">
          <label class="mb-4">Additional configurations</label>
          <CRow
            v-if='!hide_format'
            class="form-group"
          >
            <CCol
              tag="label"
              sm="5"
              class="col-form-label"
            >
              Report format
            </CCol>

            <CCol sm="7">
              <CFormCheck
                v-for='file in format_options.file'
                :id="file.value"
                :key='file'
                v-model='reports.file_format'
                type="radio"
                name="file_format"
                :value="file.value"
                :label="file.label"
              />
            </CCol>
          </CRow>

          <CRow
            v-if='!hide_pgp'
            class="form-group"
          >
            <CCol
              tag="label"
              sm="5"
              class="col-form-label"
            >
              PGP encryption
            </CCol>

            <CCol sm="7">
              <div class="mb-3">
                <div class="form-check">
                  <input
                    id='pgp_enabled'
                    class="form-check-input"
                    type="checkbox"
                    name='pgp_enabled'
                    :checked='is_pgp_enabled'
                    @change="onPgpChange"
                  >
                </div>
              </div>

              <template v-if="is_pgp_enabled">
                <VeeForm
                  v-slot="{ meta }"
                  :validateOnInput='true'
                  :validateOnChange='true'
                  validateOnMount
                >
                  <span class='ada-hidden'>{{ validateForm(meta) }}</span>
                  <Field
                    v-slot="{ field, meta }"
                    v-model='reports.encryption.pgp.email'
                    rules='required|email'
                    name='pgp_email'
                  >
                    <label
                      for="pgp_email"
                      class="form-label"
                    >
                      Email
                    </label>

                    <input
                      id='pgp_email'
                      type='email'
                      v-bind='field'
                      class='form-control'
                      :class="validationClass(meta)"
                    >
                  </Field>

                  <Field
                    v-slot="{ field, meta }"
                    v-model='reports.encryption.pgp.public_key'
                    rules='required'
                    name='pgp_public_key'
                  >
                    <label
                      for="pgp_public_key"
                      class="form-label"
                    >
                      PGP Public key
                    </label>

                    <textarea
                      id='pgp_public_key'
                      rows='15'
                      v-bind='field'
                      class='form-control'
                      :class="validationClass(meta)"
                      @input="(e) => { reports.encryption.pgp.public_key = adjustNewlines(e) }"
                    />
                  </Field>
                </VeeForm>
              </template>
            </CCol>
          </CRow>

          <CRow class="form-group">
            <CCol
              tag="label"
              sm="5"
              class="col-form-label"
            >
              Pipe delimited
              <br>
              <small class="form-text text-muted w-100">If selected, CSV will be separated by |</small>
            </CCol>

            <CCol sm="7">
              <CFormCheck
                v-model="reports.pipe_delimited"
                custom
              />
            </CCol>
          </CRow>

          <CFormSelect
            v-model="reports.date_format"
            label="Date format"
            :options="format_options.date"
          />

          <CRow
            v-if='show_date_range'
            class="form-group"
          >
            <VeeForm
                v-slot="{ meta }"
                :validateOnInput='true'
                :validateOnChange='true'
                validateOnMount
            >
             <span class='ada-hidden'>{{ validateStartDate(meta) }}</span>
             <span class='ada-hidden'>{{ validateEndDate(meta) }}</span>
               <Field
                  v-slot="{ field, meta }"
                  v-model='reports.date_start'
                  rules='required'
                  name='date_start'
               >
                <CCol sm="6">
                  <label for="date_start">Date Start:</label>
                  <br>
                  <input
                    id="date_start"
                    v-model='reports.date_start'
                    type="date"
                    name="date-start"
                    v-bind='field'
                    class='form-control'
                   :class="validationClass(meta)"
                  >
                </CCol>
              </Field>
              <Field
                  v-slot="{ field, meta }"
                  v-model='reports.date_end'
                  rules='required'
                  name='date_end'
               >
                <CCol sm="6">
                  <label for="date_end">Date End:</label>
                  <br>
                  <input
                    id="date_end"
                    v-model='reports.date_end'
                    type="date"
                    name="date-end"
                    v-bind='field'
                    class='form-control'
                    :class="validationClass(meta)"
                  >
                </CCol>
              </Field>
            </VeeForm>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import draggable from 'vuedraggable'

import { CIcon } from '@coreui/icons-vue'
import { inject } from 'vue'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import {
  required,
  email
} from '@vee-validate/rules'

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CFormInput,
  CFormCheck,
  CLink,
  CRow,
  CFormSelect,
  CFormTextarea
} from '@coreui/vue'

export default {
  props: [
    'configuration',
    'properties',
    'root_access',

    'hide_pgp',
    'hide_format',
    'show_date_range'
  ],

  emits: [
    'change',
    'update-validity'
  ],

  components: {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CCollapse,
    CIcon,
    CFormInput,
    CFormCheck,
    CLink,
    CRow,
    CFormSelect,
    VeeForm,
    Field,
    draggable
  },

  watch: {
    reports: {
      handler: function(value) {
        this.$emit('change', 'reports', value)
      },
      deep: true
    },
    'form_valid': function(value) {
      this.$emit('update-validity', 'reports', value)
    },

    'start_date_valid': function(value) {
      this.$emit('update-validity', 'start_date', value);
    },
  },

  data() {
    return {
      reports: {},
      attributes: {},
      collapse_state: {},
      format_options: {
        file: [
          { label: 'CSV', value: 'csv' },
          { label: 'Zip', value: 'zip' }
        ],
        date: [
          { label: '01/20/21', value: '%m/%d/%y' },
          { label: '01/20/2021', value: '%m/%d/%Y' },
          { label: '01-20-21', value: '%m-%d-%y' },
          { label: '01-20-2021', value: '%m-%d-%Y' },
          { label: '2021-01-20', value: '%Y-%m-%d' }
        ]
      },
      drag_options: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      form_valid: true,
      start_date_valid: false,
      end_date_valid: false
    }
  },

  created() {
    this.icons = inject('icons')
    this.reports = this.configuration && Object.assign({}, this.configuration)
    if (!this.reports.file_format) {
      this.reports.file_format = this.format_options.file[0].value
    }
    if (!this.reports.date_format) {
      this.reports.date_format = this.format_options.date[0].value
    }

    // validation rules
    defineRule('required', required);
    defineRule('email', email);
  },

  mounted() {
    this.$emit('update-validity', 'reports', this.form_valid)
    this.$emit('update-validity', 'start_date', this.start_date_valid)
    this.$emit('update-validity', 'end_date', this.end_date_valid)
  },

  computed: {
    supported_reports() {
      const options = {}
      this.properties.supported.forEach((r) => (options[r] = _.startCase(r)))
      return options
    },

    enabled_reports() {
      return this.reports.enabled || []
    },

    supported_activity_types() {
      return this.properties.supported_activities
    },

    enabled_activity_types() {
      return this.reports.enabled_activity_types
    },

    is_pgp_enabled() {
      return !!(this.reports.encryption && this.reports.encryption.pgp)
    }
  },

  methods: {
    isReportEnabled(report) {
      return this.enabled_reports.includes(report)
    },

    onReportEnabledChange({ target }) {
      const report = target.value
      if (target.checked) {
        if (!this.reports.enabled) {
          this.reports.enabled = []
        }
        this.reports.enabled.push(report)
      } else {
        this.reports.enabled = this.reports.enabled.filter(r => r !== report)
        if (!this.reports.enabled.length) {
          delete this.reports['enabled']
        }
      }
    },

    isActivityTypeEnabled(type) {
      return !this.enabled_activity_types || this.enabled_activity_types.includes(type)
    },

    onActivityTypeEnabledChange({ target }) {
      const type = target.value
      if (target.checked) {
        this.enabled_activity_types.push(type)
      } else {
        this.reports.enabled_activity_types = (this.enabled_activity_types || this.supported_activity_types).filter(r => r !== type)
      }
    },

    toggleReportConfiguration(report) {
      this.collapse_state[report] = !this.collapse_state[report]
    },

    attributeMapToArray(mapping) {
      const arr = []
      if (mapping) {
        Object.entries(mapping).forEach(item => {
          arr.push({ attr_name: item[0], attr_value: item[1] })
        })
      }
      return arr
    },

    arrayToAttributeMap(array) {
      const mapping = {}
      array.forEach(item => {
        if (!(item.attr_name && item.attr_name.length)) return
        mapping[item.attr_name] = item.attr_value
      })
      return mapping
    },

    defaultReportAttributes(report) {
      return this.properties.attribute_map[report]
    },

    reportAttributes(report) {
      if (!this.attributes[report]) {
        const mapping = this.reports[report] || this.defaultReportAttributes(report)
        this.attributes[report] = this.attributeMapToArray(mapping)
      }
      return this.attributes[report]
    },

    syncAttributes(report) {
      const mapping = this.arrayToAttributeMap(this.attributes[report])
      if (JSON.stringify(mapping) === JSON.stringify(this.defaultReportAttributes(report))) {
        delete this.reports[report]
      } else {
        this.reports[report] = mapping
      }
    },

    addAttribute(report) {
      this.attributes[report].push({ attr_name: null, attr_value: null })
    },

    deleteAttribute(report, element) {
      const index = this.attributes[report].indexOf(element)
      this.attributes[report].splice(index, 1)
      this.syncAttributes(report)
    },

    isEditableAttribute(report, column) {
      return this.root_access && !(column && column.length && this.defaultReportAttributes(report).hasOwnProperty(column))
    },

    onPgpChange({ target }) {
      if (target.checked) {
        this.reports.encryption = { pgp: { email: null, public_key: null } }
      } else {
        delete this.reports.encryption
      }
    },

    validationClass(meta) {
      return {
        'is-invalid': meta.validated && !meta.valid
      }
    },

    validateForm(meta) {
      this.form_valid = meta.valid
    },

    validateStartDate(meta) {
      this.start_date_valid = meta.valid
    },

    validateEndDate(meta) {
      this.end_date_valid = meta.valid
    },

    adjustNewlines(e) {
      e.target.value = e.target.value.replace(/\\n/g, '\n')
      return e.target.value
    }
  }
}
</script>

<style lang='sass' scoped>
@import './Reports'

.invalid-feedback
  display: block
</style>
