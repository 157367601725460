<template>
<div>
  <CSpinner v-if='saml_configurations_loading' />

  <template v-else>
    <CTable
      hover
      striped
      bordered
      align="middle"
    >
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">
            Entity Id
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Uses as an Identity Provider
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Expiration Date
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Status
          </CTableHeaderCell>
          <CTableHeaderCell
            scope="col"
            style='width: 4%'
          />
        </CTableRow>
      </CTableHead>

      <CTableBody>
        <CTableRow
          v-for='(configuration, index) in saml_configurations'
          :key='index'
        >
          <CTableDataCell>
            {{ configuration.entity_id }}
          </CTableDataCell>

          <CTableDataCell>
            <p
              v-for="subdomain, i in configuration.idp_subdomains"
              :key="i"
            >
              {{ subdomain }}
            </p>
          </CTableDataCell>

          <CTableDataCell>
            {{ format_date(configuration) }}
          </CTableDataCell>

          <CTableDataCell>
            <CBadge :color="certificateStatus(configuration)['color']">
              {{ certificateStatus(configuration)['label'] }}
            </CBadge>
          </CTableDataCell>

          <CTableDataCell>
            <CButton
              size='sm'
              @click.prevent='openSamlConfigModal(configuration)'
            >
              <CIcon :icon="icons.cilPencil" />
            </CButton>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
  </template>

  <SamlConfigModal
    :opened='saml_config_modal_opened'
    :closeModal='closeSamlConfigModal'
    :saml_config='current_saml_config'
    :api_client='api_client'
    @saml-config-updated='updateSamlConfig'
  />
</div>
</template>

<script>
import {
  CButton,
  CBadge,
  CSpinner
} from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody
} from '@coreui/vue'

import SamlConfigModal from './SamlConfigModal/SamlConfigModal'

import { inject } from 'vue'
import { format, add } from 'date-fns'

export default {
  props: [ 'api_client' ],

  components: {
    CSpinner,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CButton,
    CIcon,
    CBadge,
    SamlConfigModal
  },

  data() {
    return {
      saml_configurations: [],
      saml_configurations_loading: false,
      saml_config_modal_opened: false,
      current_saml_config: null,
      current_saml_config_index: null,
      statuses: {
        0: { label: 'Expired', color: 'danger' },
        1: { label: 'Expires Soon', color: 'warning' },
        2: { label: 'Active', color: 'success' },
        3: { label: 'No Certificate', color: 'secondary' }
      }
    }
  },

  created() {
    this.icons = inject('icons')
    this.fetchSamlConfigurations()
  },

  methods: {
    fetchSamlConfigurations() {
      this.saml_configurations_loading = true
      this.api_client.findAll('saml_configurations')
        .then(({ data }) => {
          this.saml_configurations = data
          this.saml_configurations_loading = false
        })
    },

    format_date(item) {
      if (item.expiration_date) {
        return format(new Date(item.expiration_date), 'yyyy-MM-dd')
      }
      return null
    },

    openSamlConfigModal(config) {
      this.current_saml_config = config
      this.current_saml_config_index = this.saml_configurations.indexOf(config)
      this.saml_config_modal_opened = true
    },

    closeSamlConfigModal() {
      this.current_saml_config = null
      this.current_saml_config_index = null
      this.saml_config_modal_opened = false
    },

    updateSamlConfig(data) {
      this.saml_configurations.splice(this.current_saml_config_index, 1, data)
      this.current_saml_config = data
    },

    certificateStatus(config) {
      if (config.expiration_date) {
        const today = Date.now()
        const expDate = new Date(config.expiration_date)
        const nextMonth = add(today, { months: 1 })

        if (expDate < today) {
          return this.statuses[0]
        }
        if (nextMonth > expDate && today < expDate) {
          return this.statuses[1]
        }
        return this.statuses[2]
      }
      return this.statuses[3]
    }
  }
}
</script>
