<template>
<div>
  <CSpinner
    v-if="loading"
    class="loader"
  />

  <CFormSelect
    v-if="academy_partners_loaded"
    label="Select an Academy Partner"
    placeholder=" "
    :options="ap_select_options"
    @change='apChange'
  />

  <template v-if="academy_partner">
  <CNav
    variant="pills"
    style='margin-top: 20px'
  >
    <CNavItem>
      <CNavLink
        href="javascript:void(0);"
        :active="tab_panel_active_key === 1"
        @click="() => {tab_panel_active_key = 1}"
      >
        Schedule
      </CNavLink>
    </CNavItem>

    <CNavItem>
      <CNavLink
        href="javascript:void(0);"
        :active="tab_panel_active_key === 2"
        @click="() => {tab_panel_active_key = 2}"
      >
        Download
      </CNavLink>
    </CNavItem>
  </CNav>

  <CTabContent style='padding-bottom: 15px'>
    <CTabPane
      role="tabpanel"
      :visible="tab_panel_active_key === 1"
    >
      <!-- Schedule -->
      <template v-if="configuration_loaded">
        <Reports
          :key='`scheduled_reports_${academy_partner.subdomain}`'
          :configuration="configuration.reports"
          :properties="properties.reports"
          :root_access="configuration.root_access"
          @change="updateNestedConfig"
          @update-validity="updateValidity"
        />

        <Sftp
          :configuration="configuration.sftp"
          @change="updateNestedConfig"
          @update-validity="updateValidity"
        />

        <Trigger
          :configuration="configuration.trigger"
          @change="updateNestedConfig"
        />

        <CButton
          color="primary"
          :disabled="submit_disabled"
          @click="submitConfiguration"
        >
          Save
        </CButton>
      </template>
    </CTabPane>

    <CTabPane
      role="tabpanel"
      :visible="tab_panel_active_key === 2"
    >
      <!-- Download -->
      <Reports
        v-if="configuration_for_download_loaded"
        :key='`download_reports_${academy_partner.subdomain}`'
        :configuration="configuration_for_download.reports"
        :properties="properties.reports"
        :root_access="configuration.root_access"
        show_date_range="true"
        hide_pgp="true"
        hide_format="true"
        @change="updateNestedConfigForDownload"
        @update-validity="updateValidity"
      />

      <CButton
        color="primary"
        :disabled="download_report_btn_disabled"
        class='download-btn'
        @click="downloadReport"
      >
        Download
      </CButton>
    </CTabPane>
  </CTabContent>
  </template>
</div>
</template>

<script>
import {
  CButton,
  CSpinner,
  CFormSelect,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/vue'

import Reports from './ReportsList'
import Sftp from './ReportsSftp'
import Trigger from './ReportsTrigger'

export default {
  props: [
    'api_client',
    'properties'
  ],

  components: {
    CButton,
    CSpinner,
    CFormSelect,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    Reports,
    Sftp,
    Trigger
  },

  watch: {
    configuration_loaded: function(value) {
      if (value) this.fetchConfigurationForDownload()
    }
  },

  data() {
    return {
      academy_partners: [],
      academy_partner: null,

      configuration: {},
      configuration_for_download: {},

      tab_panel_active_key: 1,

      academy_partners_loaded: false,
      configuration_loaded: false,
      configuration_for_download_loaded: false,
      submit_in_progress: false,
      reports_valid: true,
      sftp_valid: false,
      download_in_progress: false,
      start_date_valid: false,
      end_date_valid: false
    }
  },

  created() {
    this.fetchAcademyPartners()
  },

  computed: {
    loading() {
      return !this.academy_partners_loaded ||
              this.submit_in_progress ||
              (this.academy_partner && !this.configuration_loaded)
    },

    ap_select_options() {
      let options = [{label: '', value: ''}]
      this.academy_partners.map((ap) => {
        options.push({
          label: ap.subdomain,
          value: ap.id
        })
      })
      return options
    },

    submit_disabled() {
      return !(this.reports_valid && this.sftp_valid)
    },

    has_localstorage_access() {
      try {
        const storage = window.localStorage
        const test = '__storage_test__'
        storage.setItem(test, test)
        storage.removeItem(test)
        return true
      } catch (e) {
        return false
      }
    },

    download_report_btn_disabled() {
      return this.download_in_progress || !this.start_date_valid || !this.end_date_valid ||
        (this.configuration_for_download && !this.configuration_for_download.reports.enabled)
    }
  },

  methods: {
    apChange({target}) {
      this.academy_partner = this.academy_partners.filter((ap) => ap.id === target.value)[0]
      this.fetchConfiguration()
    },

    fetchAcademyPartners() {
      this.api_client.findAll('academy_partners')
        .then(({ data }) => {
          this.academy_partners = data
          this.academy_partners_loaded = true
        }
        )
    },

    fetchConfiguration() {
      if (!this.academy_partner) return

      this.configuration_loaded = false
      this.configuration_for_download_loaded = false
      this.configuration = null
      this.configuration_for_download = null

      this.api_client.one('academy_partner', this.academy_partner.id)
        .all('reports_configuration')
        .get()
        .then(({ data }) => {
          this.configuration = data
          this.configuration_loaded = true
        })
        .catch((error) => {
          console.log(error)
        })
    },

    fetchConfigurationForDownload() {
      if (!this.has_localstorage_access || !this.academy_partner) return

      this.configuration_for_download_loaded = false
      this.configuration_for_download = null

      const saved_configuration = localStorage.getItem('academy_partner_' + this.academy_partner.subdomain)
      if (saved_configuration) {
        this.configuration_for_download = JSON.parse(saved_configuration)
      } else {
        this.configuration_for_download = Object.assign({}, {
          reports: this.configuration.reports
        })
      }
      this.configuration_for_download_loaded = true
    },

    saveConfigurationForDownload() {
      if (this.has_localstorage_access) {
        localStorage.setItem('academy_partner_' + this.academy_partner.subdomain, JSON.stringify(this.configuration_for_download))
      }
    },

    submitConfiguration() {
      this.submit_in_progress = true
      this.api_client.one('academy_partner', this.academy_partner.id)
        .all('reports_configuration')
        .patch({
          sftp: this.configuration.sftp,
          reports: this.configuration.reports,
          trigger: this.configuration.trigger
        })
        .then(({ data }) => {
          this.configuration = data
        })
        .catch((error) => {
          Tbk.alerts.new('Sorry, but something went wrong', 'error')
          console.log(error)
        })
        .finally(() => {
          this.submit_in_progress = false
        })
    },

    async downloadReport() {
      this.download_in_progress = true;
      this.saveConfigurationForDownload();

      const download_url = this.api_client.resourceUrlFor('academy_partner', this.academy_partner.id) + '/download_report';

      this.api_client.axios.defaults.headers.common.Authorization = this.api_client.headers.Authorization;

      this.api_client.axios({
        url: download_url,
        method: 'POST',
        responseType: 'blob',
        data: {
          data: {
            configuration: this.configuration_for_download
          }
        }
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.academy_partner.subdomain}_report.${response.headers['content-type']}`);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(url), 0);
      })
      .catch(async error => {
        console.log("Error:", error);
        if (error.response && error.response.data instanceof Blob) {
          await error.response.data.text().then(error_string => {
            Tbk.alerts.new(error_string, 'error');
          }).catch(() => {
            Tbk.alerts.new('Sorry, an error occurred while downloading the report.', 'error');
          });
        } else {
          Tbk.alerts.new('Sorry, an error occurred while downloading the report.', 'error');
        }
      })
      .finally(() => {
        this.download_in_progress = false;
      });
    },

    updateNestedConfig(configName, value) {
      this.configuration[configName] = value
    },

    updateNestedConfigForDownload(configName, value) {
      this.configuration_for_download[configName] = value
    },

    updateValidity(configName, value) {
      if (configName === 'sftp') {
        this.sftp_valid = value
      } else {
        this[`${configName}_valid`] = value
      }
    }
  }
}
</script>

<style lang='sass'>
.reports-tabs
  margin-bottom: 0.5em

.download-btn
  margin-bottom: 0.5em
</style>
