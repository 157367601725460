<template>
  <div class="user-info-modal">
    <CSpinner
      v-if="!is_enrollments_loaded"
      class="loader"
    />

    <template v-else>
      <UserInfo
        :user="user"
        :api_client="api_client"
      />

      <div
        class="mt-3 mb-3"
        style='padding: 0 10px'
      >
        <CAlert
          v-if="enrollments.length === 0"
          color="primary"
        >
          The user is not enrolled in any course.
        </CAlert>

        <template v-else>
          <h5>Courses</h5>
          <CourseEnrollments
            :enrollments='enrollments'
            :resetProgress='resetProgress'
            :completeProgress='completeProgress'
            :is_admin_root='is_admin_root'
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { CAlert, CSpinner } from '@coreui/vue'
import UserInfo from './UserInfo'
import CourseEnrollments from './Enrollments/CourseEnrollments'

export default {
  components: {
    CAlert,
    CSpinner,
    CourseEnrollments,
    UserInfo
  },

  props: ['api_client', 'user', 'is_admin_root'],

  data() {
    return {
      enrollments: [],
      is_enrollments_loaded: false
    }
  },

  created() {
    this.fetchEnrollments()
  },

  methods: {
    fetchEnrollments() {
      const that = this

      this.api_client.axios.defaults.headers.common.Authorization = this.api_client.headers.Authorization
      this.api_client.axios.get(`${this.api_client.apiUrl}/users/${this.user.id}/enrollments`)
        .then(function(response) {
          that.enrollments = response.data
          that.is_enrollments_loaded = true
        })
        .catch(function(error) {
          if (error.response.status === 401) window.location.reload()
          console.log('error: ' + error.response)
        })
    },

    resetProgress(type, id) {
      let reset_url = `${this.api_client.apiUrl}/users/${this.user.id}`
      if (type === 'course') reset_url += `/enrollments/reset_progress?course_id=${id}`
      if (type === 'lesson') reset_url += `/enrollments/reset_progress?lesson_id=${id}`
      if (type === 'slide')  reset_url += `/slide_activities/reset_progress?slide_id=${id}`
      reset_url += '&override_progress_confirmation=true'

      const that = this
      this.api_client.axios.defaults.headers.common.Authorization = this.api_client.headers.Authorization
      this.api_client.axios.delete(reset_url)
        .then(function(response) {
          if (response.data.message) {
            Tbk.alerts.new(response.data.message, 'success')
          }
          // here we need to refetch enrollments
          that.fetchEnrollments()
        })
        .catch(function(error) {
          Tbk.alerts.new('Error ' + error.response.data, 'error')
        })
    },

    completeProgress(type, id) {
      let complete_url = `${this.api_client.apiUrl}/users/${this.user.id}`
      if (type === 'slide')  complete_url += `/slide_activities/force_complete?slide_id=${id}`
      complete_url += '&override_progress_confirmation=true'

      const that = this
      this.api_client.axios.defaults.headers.common.Authorization = this.api_client.headers.Authorization
      this.api_client.axios.get(complete_url)
        .then(function(response) {
          if (response.data.message) {
            Tbk.alerts.new(response.data.message, 'success')
          }
          // here we need to refetch enrollments
          that.fetchEnrollments()
        })
        .catch(function(error) {
          Tbk.alerts.new('Error ' + error.response.data, 'error')
        })
    }
  }
}
</script>

<style lang='sass' scoped>
@import './UserModal'
</style>
