<template>
<div>
  <ul class="list-group">
    <li
      v-for="slide in slides"
      :key="slide.identifier"
      class="list-group-item"
    >
      <div
        class="row"
        style='alignItems: center'
      >
        <div class="col-md-7">
          {{ slide.title }}
        </div>

        <div class="col-md-3">
          {{ slide.type }}
        </div>

        <div class="col-md-1">
          <button
            v-if='showReset(slide)'
            type="button"
            class="btn btn-sm btn-ghost-primary"
            @click.prevent="resetClick(slide)"
          >
            Reset
          </button>

          <button
            v-if="!slide.completion_date && slideCanBeCompleted(slide)"
            type="button"
            class="btn btn-sm btn-ghost-primary"
            @click.prevent="completeClick(slide)"
          >
            Complete
          </button>
        </div>

        <div class="col-md-1 text-right">
          <CBadge
            v-if="slide.completion_date"
            color="success"
            size='sm'
          >
            Completed
          </CBadge>
        </div>
      </div>
    </li>
  </ul>

  <!-- Confirm Modals -->
  <!-- Confirm Reset -->
  <CModal
    alignment='center'
    :visible='show_reset_confirm'
    @close='closeResetModal'
  >
    <CModalHeader>
      <h5 class='modal-title'>
        Reset {{ slide_to_reset?.title }}
      </h5>
    </CModalHeader>

    <CModalBody>
      The <strong>{{ slide_to_reset?.title }}</strong> progress will be reset.
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeResetModal"
      >
        Cancel
      </CButton>

      <CButton
        color="primary"
        @click="resetSlideProgress"
      >
        Confirm Reset
      </CButton>
    </CModalFooter>
  </CModal>

  <!-- Confirm Complete -->
  <CModal
    :visible='show_complete_confirm'
    alignment='center'
    @close='closeCompleteModal'
  >
    <CModalHeader>
      <h5 class='modal-title'>
        Mark Complete {{ slide_to_complete?.title }}
      </h5>
    </CModalHeader>

    <CModalBody>
      The <strong>{{ slide_to_complete?.title }}</strong> progress will be marked complete.
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeCompleteModal"
      >
        Cancel
      </CButton>

      <CButton
        color="primary"
        @click="completeSlide"
      >
        Mark Complete
      </CButton>
    </CModalFooter>
  </CModal>
</div>
</template>

<script>
import {
  CBadge,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/vue'

export default {
  components: {
    CBadge,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton
  },

  props: ['slides', 'resetProgress', 'completeProgress', 'is_admin_root'],

  data() {
    return {
      show_reset_confirm: false,
      slide_to_reset: null,

      show_complete_confirm: false,
      slide_to_complete: null
    }
  },

  methods: {
    slideCanBeCompleted(slide) {
      const slide_types = [
        'Activity::V1::Video',
        'Activity::V1::VideoWithInstructions',
        'Activity::V1::Profile',
        'Activity::V1::Audio',
        'Activity::V1::BasicContent',
        'Activity::V1::Article',
        'Activity::V1::LinkedFile',
        'Activity::V1::Fullscreen'
      ]
      const has_progress = slide.status && slide.status !== 'passed'

      return this.is_admin_root && has_progress && slide_types.includes(slide.type)
    },

    showReset(slide) {
      return this.is_admin_root && slide.completion_date
    },

    closeResetModal() {
      this.show_reset_confirm = false
      this.slide_to_reset = null
    },

    closeCompleteModal() {
      this.show_complete_confirm = false
      this.slide_to_complete = null
    },

    resetClick(slide) {
      this.show_reset_confirm = true
      this.slide_to_reset = slide
    },

    completeClick(slide) {
      this.show_complete_confirm = true
      this.slide_to_complete = slide
    },

    resetSlideProgress() {
      this.resetProgress('slide', this.slide_to_reset.id)
      this.closeResetModal()
    },

    completeSlide() {
      this.completeProgress('slide', this.slide_to_complete.id)
      this.closeCompleteModal()
    }
  }
}
</script>
