// Based on API::Admin::V1::AdminUserSerializer
export default {
  // Relationships
  roles: {
    jsonApi: 'hasMany',
    type: 'admin_roles'
  },

  // Attributes
  email: null,
  first_name: null,
  last_name: null,
  password: null,
  enabled: false
}
