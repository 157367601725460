<template>
  <CRow align-horizontal='center'>
    <CCol md='12'>
      <CFormInput
        v-model='saml_config.entity_id'
        label='Entity Id'
        name='entity_id'
        plaintext
        class='mb-2'
      />

      <CFormTextarea
        v-model='saml_config.certificate'
        label="SSO Certificate"
        name='certificate'
        placeholder='Copy and paste your new SSO certificate here. Then, click Save.'
        rows='15'
      />
    </CCol>
  </CRow>
</template>

<script>
import {
  CCol,
  CRow,
  CFormInput,
  CFormTextarea
} from '@coreui/vue'

export default {
  props: [ 'saml_config' ],

  components: {
    CCol,
    CRow,
    CFormInput,
    CFormTextarea
  }
}
</script>
