<template>
  <CModal
    centered
    size="xl"
    :closeOnBackdrop='false'
    :visible='opened'
  >
    <CModalBody>
      <SamlConfigInfo
        v-if='opened'
        :saml_config='saml_config_data'
      />
    </CModalBody>

    <CModalFooter>
      <CButton
        color='primary'
        hover
        :disabled='submit_disabled'
        @click='saveSamlConfig'
      >
        Save
      </CButton>

      <CButton
        color='secondary'
        hover
        @click.prevent='closeModal'
      >
        Close
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/vue'

import SamlConfigInfo from './SamlConfigInfo.vue'

export default {
  props: [
    'opened',
    'closeModal',
    'saml_config',
    'api_client'
  ],

  emits: ['saml-config-updated'],

  components: {
    CModal,
    CModalBody,
    CModalFooter,
    CButton,
    SamlConfigInfo
  },

  watch: {
    opened: function() {
      this.saml_config_data = this.saml_config ? JSON.parse(JSON.stringify(this.saml_config)) : {}
    }
  },

  data() {
    return {
      submit_in_progress: false,
      saml_config_data: {}
    }
  },

  computed: {
    saml_config_changed() {
      return !_.isEqual(this.saml_config, this.saml_config_data)
    },

    submit_disabled() {
      return !this.saml_config_changed || this.submit_in_progress
    }
  },

  methods: {
    saveSamlConfig() {
      this.submit_in_progress = true
      const callback = () => { this.submit_in_progress = false }
      this._updateSamlConfig(callback)
    },

    _updateSamlConfig(callback) {
      this.api_client.update('saml_configuration', this.saml_config_data)
        .then(({ data }) => {
          this.$emit('saml-config-updated', data)
          Tbk.alerts.new('SSO Certificate was successfully updated', 'success')
        })
        .catch((error) => {
          if (error.saml_configuration) {
            Tbk.alerts.new(`${error.saml_configuration.detail}`, 'error')
          } else {
            Tbk.alerts.new('Sorry, but something went wrong', 'error')
          }
        })
        .finally(() => {
          callback()
        })
    }
  }
}
</script>
