import ApiClient from 'devour-client'
// API::V1 models are necessary for Users and Embed Generator tools
import ModelLoader from 'api/models'
import AdminModelLoader from './models'

import axios from 'axios'

const apiClient = new ApiClient({})
apiClient.axios = axios

apiClient.setToken = (token) => {
  apiClient.headers.Authorization = `Bearer ${token}`
}

apiClient.setSubdomain = (subdomain) => {
  apiClient.headers['X-TBK-AcademyHost'] = subdomain
}

apiClient.setUrl = (url) => {
  apiClient.apiUrl = url
}

function _csrfToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

const railsCsrfMiddleware = {
  name: 'rails-csrf-middleware',
  req: (payload) => {
    if (payload.req.method !== 'GET') {
      payload.req.headers['X-CSRF-TOKEN'] = _csrfToken()
      payload.jsonApi.axios.defaults.withCredentials = payload.jsonApi.use_cookies
    }
    return payload
  }
}

const sessionTimeoutErrorMiddleware = {
  name: 'session-timeout-error-middleware',
  error: (payload) => {
    if (payload.response && payload.response.status === 401) {
      window.location.reload()
    }
    return payload
  }
}

apiClient.insertMiddlewareBefore('axios-request', railsCsrfMiddleware)
apiClient.insertMiddlewareBefore('errors', sessionTimeoutErrorMiddleware)

ModelLoader.load(apiClient)
AdminModelLoader.load(apiClient)

export { apiClient as default }
