<template>
  <div class="admin-embed-chooser">
    <CSpinner
      v-if='!is_academy_partners_loaded'
    />

    <template v-else>
      <label>Select an Academy Partner:</label>
      <div class="input-group mb-3">
        <select
          v-model="current_academy_partner"
          class='form-select custom-select'
        >
          <option
            v-for="ap in academy_partners"
            :key="ap.id"
            :value="ap"
          >
            {{ ap.subdomain }}
          </option>
        </select>
      </div>
    </template>

    <template v-if="current_academy_partner">
      <label>Choose the domain:</label>
      <div class="input-group mb-3">
        <select
          v-model="selected_fqdn"
          class="form-select custom-select"
        >
          <option
            v-for="domain in current_academy_partner.fqdn_combinations"
            :key="domain"
            :value="domain"
          >
            {{ domain }}
          </option>
        </select>
      </div>
    </template>

    <template v-if="selected_fqdn">
      <CSpinner v-if='!is_data_loaded' />

      <template v-else>
        <label>Choose the item type:</label>
        <div class="input-group mb-3">
          <select
            v-model="current_embed_type"
            class='form-select custom-select'
          >
            <option
              v-for="t in embeddable_types"
              :key="t"
              :value="t"
            >
              {{ t }}
            </option>
          </select>
        </div>
      </template>
    </template>

    <template v-if="current_embed_type">
      <template v-if="current_embed_type === 'lesson'">
        <label>Choose the parent course:</label>
        <div class="input-group mb-3">
          <select
            v-model="parent_course"
            class='form-select custom-select'
          >
            <option
              v-for="e in embeddablesForType('course')"
              :key="e.identifier"
              :value="e"
            >
              {{ e.identifier }}
            </option>
          </select>
        </div>
      </template>

      <template v-if="current_embed_type !== 'lesson' || parent_course">
        <label>Choose the item you want to embed:</label>
        <div class="input-group mb-3">
          <select
            v-model="current_embed"
            class='form-select custom-select'
          >
            <option
              v-for="e in embeddable_items"
              :key="e.identifier"
              :value="e"
            >
              {{ e.identifier }}
            </option>
          </select>
        </div>
      </template>
    </template>

    <template v-if="current_embed">
      <label>Embed code:</label>
      <div class="input-group mb-3">
        <textarea
          ref="embed-code-text"
          class="form-control"
          cols="100"
          rows="12"
          v-html="gen_code"
        />
      </div>

      <button
        class='btn btn-primary mb-3'
        @click.prevent="copyToClipboard('embed-code-text')"
      >
        Copy
      </button>

      <template v-if="embed_url">
        <label class="d-block">Standalone URL:</label>
        <div class="input-group mb-3">
          <input
            ref="embed-code-url"
            class="form-control"
            :value="embed_url"
          >

          <div class="input-group-append">
            <CButtonGroup>
              <CButton
                color="primary"
                @click.prevent="copyToClipboard('embed-code-url')"
              >
                Copy
              </CButton>
              <CButton
                color="primary"
                target="_blank"
                :href="embed_url"
                component='a'
              >
                <CIcon :icon="$options.icons.external_link" />
              </CButton>
            </CButtonGroup>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { CButton, CButtonGroup } from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import { cilExternalLink } from '@coreui/icons'
import { CSpinner } from '@coreui/vue'

export default {
  props: ['api_client', 'admin_api_url', 'api_url', 'embed_loader_url'],

  icons: {
    external_link: cilExternalLink
  },

  components: {
    CButton,
    CButtonGroup,
    // CElementCover,
    CSpinner,
    CIcon
  },

  watch: {
    current_academy_partner: function() {
      this.fetchEmbeds()
    },
    current_embed_type: function() {
      this.parent_course = null
      this.current_embed = null
    },
    parent_course: function() {
      this.current_embed = null
    }
  },

  data() {
    return {
      academy_partners: [],
      embeddables: {},
      current_academy_partner: null,
      current_embed_type: null,
      selected_fqdn: null,
      current_embed: null,
      parent_course: null,
      is_academy_partners_loaded: false,
      is_global_slides_loaded: false,
      is_audio_series_loaded: false,
      is_courses_loaded: false,
      supported_activity_types: [
        'activity_v1_articles',
        'activity_v1_audios',
        'activity_v1_basic_contents',
        'activity_v1_discussions',
        'activity_v1_fullscreens',
        'activity_v1_intention_setters',
        'activity_v1_journals',
        'activity_v1_paired_comparisons',
        'activity_v1_polls',
        'activity_v1_quizzes',
        'activity_v1_resources',
        'activity_v1_self_assessments',
        'activity_v1_videos',
        'activity_v1_video_with_instructions',
        'activity_v2_self_assessments'
      ],
      activity_regexp: /^activity_v[1-9]_[a-zA-Z_]*$/,
      pluralize: require('pluralize')
    }
  },

  created() {
    this.fetchAcademyPartners()
  },

  methods: {
    fetchAcademyPartners() {
      this.api_client.setUrl(this.admin_api_url)
      this.api_client.findAll('academy_partners').then(
        function({ data }) {
          this.academy_partners = data
          this.is_academy_partners_loaded = true
        }.bind(this)
      )
    },

    fetchEmbeds() {
      this.current_embed = null
      this.current_embed_type = null
      this.embeddables = {}
      this.api_client.setUrl(this.api_url)
      this.api_client.setSubdomain(this.current_academy_partner.subdomain)
      this.fetchGlobalSlides()
      this.fetchAudioSeries()
      this.fetchCourses()
    },

    fetchGlobalSlides() {
      this.is_global_slides_loaded = false
      this.api_client.findAll('global_slides', { include: 'activity' }).then(
        function({ data }) {
          data.forEach((e) => {
            if (this.supported_activity_types.filter((t) => t === e.activity.type).length) {
              this.pushEmbeddableItem({
                type: e.activity.type,
                identifier: e.identifier
              })
            }
          })
          this.is_global_slides_loaded = true
        }.bind(this)
      )
    },

    fetchAudioSeries() {
      this.is_audio_series_loaded = false
      this.api_client.findAll('audio_series', { fields: { audio_series: ['identifier'] } }).then(
        function({ data }) {
          data.forEach((e) => this.pushEmbeddableItem(e))
          this.is_audio_series_loaded = true
        }.bind(this)
      )
    },

    fetchCourses() {
      this.is_courses_loaded = false
      this.api_client.findAll('courses', {
        include: 'lessons',
        fields: { courses: ['identifier', 'lessons'], lessons: ['identifier'] }
      }).then(
        function({ data }) {
          data.forEach((e) => {
            const lessons = e.lessons
            delete e.lessons
            lessons.forEach((l) => {
              l.course = e
              this.pushEmbeddableItem(l)
            })
            this.pushEmbeddableItem(e)
          })
          this.is_courses_loaded = true
        }.bind(this)
      )
    },

    mapType(type) {
      switch (type) {
        case 'audio_series':
        case 'courses':
        case 'lessons':
          return this.pluralize.singular(type).replace(/_/g, '-')
        case 'activity_v1_audios':
          return 'audio'
        default: {
          if (this.activity_regexp.test(type)) {
            return 'activity'
          } else {
            return null
          }
        }
      }
    },

    pushEmbeddableItem(item) {
      const type = this.mapType(item.type)
      if (!this.embeddables[type]) {
        this.embeddables[type] = []
      }
      this.embeddables[type].push(item)
    },

    embeddablesForType(type, opts = {}) {
      let items = this.embeddables[type].slice()
      if (opts.filter) {
        const key = opts.filter[0]
        const value = opts.filter[1]
        items = items.filter((e) => e[key] === value)
      }
      if (opts.sort === undefined || opts.sort) {
        items.sort((a, b) => (a.identifier > b.identifier) ? 1 : -1)
      }
      return items
    },

    copyToClipboard(refName) {
      this.$refs[refName].select()
      document.execCommand('Copy')
      window.getSelection().removeAllRanges()
    },

    createLoaderApiUrl(fqdn, loader_url) {
        // Splits the embed loader url and selected domain fqdn and uses
        // parts of each, combining them to create the url for the embed for the
        // selected domain.
      const loaderUrlParts = loader_url.split('.');
      const fqdnParts = fqdn.split('.');
      let apiUrl;

        if (loaderUrlParts.length === 5 && fqdnParts.length === 4) { // for lower environments which include the env namespace in the url
            loaderUrlParts[1] = fqdnParts[1]; // sets the env namespace in the url
            loaderUrlParts[2] = fqdnParts[2]; // sets the domain in the url
        } else { // for prod where url does NOT include the env in the url; only need to replace the domain
            loaderUrlParts[1] = fqdnParts[1]; // sets the domain only
        }

        apiUrl = loaderUrlParts.join('.');
        return apiUrl;
      },
    },

  computed: {
    is_data_loaded() {
      return this.is_global_slides_loaded &&
        this.is_audio_series_loaded &&
        this.is_courses_loaded
    },

    embeddable_types() {
      return Object.keys(this.embeddables).sort()
    },

    embeddable_items() {
      let opts = {}
      if (this.current_embed_type === 'lesson') {
        opts = { filter: ['course', this.parent_course], sort: false }
      }
      return this.embeddablesForType(this.current_embed_type, opts)
    },

    gen_code() {
      const str =
        '<html>\n' +
        '  <head>\n' +
        '    <meta name="viewport" content="width=device-width,initial-scale=1">\n' +
        `    <script src="${this.embed_loader_api_url}?partner=${this.current_academy_partner.subdomain}" async><\/script>\n` + // eslint-disable-line no-useless-escape
        '  </head>\n' +
        '  <body>\n' +
        '    <div class="container">\n' +
        `      ${this.tbk_embed_tag}\n` +
        '    </div>\n' +
        '  </body>\n' +
        '</html>'
      return str
    },

    embed_loader_api_url() {
      if (this.selected_fqdn) {
        return this.createLoaderApiUrl(this.selected_fqdn, this.embed_loader_url)
      }
      return null
    },

    tbk_embed_tag() {
      if (this.current_embed) {
        switch (this.current_embed_type) {
          case 'lesson':
            return `<tbk-embed type="${this.current_embed_type}" course-identifier="${this.current_embed.course.identifier}" identifier="${this.current_embed.identifier}"></tbk-embed>`
          default:
            return `<tbk-embed type="${this.current_embed_type}" identifier="${this.current_embed.identifier}"></tbk-embed>`
        }
      }
      return null
    },

    embed_url() {
      if (this.embed_url_key) {
        return `${this.selected_fqdn}/landing/${this.embed_url_key}/${this.current_embed.identifier}`
      }
      return null
    },

    embed_url_key() {
      switch (this.current_embed.type) {
        case 'courses':
        case 'audio_series':
          return this.current_embed.type.replace(/_/g, '-')
        case 'activity_v1_audios':
          return 'audios'
        case 'lessons':
          return `j/courses/${this.current_embed.course.identifier}/lessons`
        default: {
          if (this.activity_regexp.test(this.current_embed.type)) {
            return 'activities'
          } else {
            return null
          }
        }
      }
    }
  }
}
</script>