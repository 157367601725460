<template>
  <div class="row gutters-sm user-info-container">
    <div class="col-md-4 mb-3">
      <div class="card-box text-center mt-3">
        <div class="mb-3">
          <img
            class="rounded-circle img-thumbnail"
            alt="profile-image"
            src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
          >
        </div>
        <h4>{{ user.first_name }} {{ user.last_name }}</h4>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card-box mb-4 mt-3">
        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">
              Full Name
            </h6>
          </div>
          <div class="col-sm-9">
            {{ user.first_name }} {{ user.last_name }}
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">
              Identifier
            </h6>
          </div>
          <div class="col-sm-9">
            {{ user.identifier }}
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">
              Email
            </h6>
          </div>
          <div class="col-sm-9">
            {{ user.email }}
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-sm-3">
            <h6 class="mb-0">
              ID
            </h6>
          </div>
          <div class="col-sm-9">
            {{ user.id }}
          </div>
        </div>
      </div>

      <div class="row gutters-sm">
        <div class="col-sm-12 mb-3 mt-4">
          <div class="card-box">
            <VeeForm
              v-slot="{ errors, meta }"
            >
              <fieldset :disabled="user.sso_only">
                <div class="form-group">
                  <h6 class="col-form-label">
                    Password
                  </h6>

                  <div class="input-group">
                    <!-- <div class="input-group-prepend"> -->
                    <!--   <div class="input-group-text"> -->
                    <!--     <a href="#" -->
                    <!--       class="toggle-password" -->
                    <!--       @click.prevent="tooglePasswordVisibility" -->
                    <!--     > -->
                    <!--       <i class="c-icon cil-low-vision" aria-hidden="true" /> -->
                    <!--     </a> -->
                    <!--   </div> -->
                    <!-- </div> -->
                    <Field
                      v-slot="{ field }"
                      v-model='password'
                      rules='password'
                      name='password'
                    >
                      <input
                        class="form-control"
                        v-bind='field'
                        :type="passwordFieldType"
                        autocomplete="new-password"
                      >
                    </Field>

                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-primary"
                        :class="{'disabled': !meta.valid}"
                        :disabled="!meta.valid"
                        @click.prevent="updatePassword"
                      >
                        Update
                      </button>
                    </div>
                  </div>

                  <div
                    v-if='user.sso_only'
                    class='error'
                  >
                    Password update is not allowed for SSO users
                  </div>

                  <div
                    v-if='!user.sso_only && password?.length && errors.password'
                    class='error'
                  >
                    {{ errors.password }}
                  </div>
                </div>
              </fieldset>
            </VeeForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'

export default {
  props: ['api_client', 'user'],

  data() {
    return {
      password: null,
      passwordFieldType: 'password'
    }
  },

  components: {
    VeeForm,
    Field
  },


  created() {
    // validation rules
    defineRule('password', (value) => {
      return value?.length >= 8
    })
  },

  methods: {
    // tooglePasswordVisibility() {
    //   this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    // },

    updatePassword() {
      const request_url = this.api_client.resourceUrlFor('users', this.user.id) + '/update_password'
      this.api_client.runMiddleware({
        url: request_url,
        method: 'PATCH',
        data: {
          password: this.password,
          password_confirmation: this.password
        },
        model: 'user'
      }).then(() => {
        Tbk.alerts.new('Password has been updated', 'success')
        this.password = null
      })
        .catch((error) => {
          Tbk.alerts.new('Sorry, but something went wrong', error)
        })
    }
  }
}
</script>

<style lang='sass' scoped>
@import './UserInfo'
</style>
