<template>
<div>
  <CSpinner v-if='!(is_data_loaded)' />

  <template v-else>
    <CAlert
      color="primary"
      close-button
    >
      <div class="d-inline-block mr-3 align-top">
        <CIcon :icon="icons.cilWarning" />
      </div>
      <div class="d-inline-block">
        Search is performed when you press Enter.
      </div>
    </CAlert>


    <CTable
      hover
      striped
      bordered
    >
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">
            URL
            <input
              class="form-control form-control-sm"
              @keyup.enter='searchAcademyPartnersBy($event, "subdomain")'
            >
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Academy
            <input
              class="form-control form-control-sm"
              @keyup.enter='searchAcademyPartnersBy($event, "academy")'
            >
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Partner
            <input
              class="form-control form-control-sm"
              @keyup.enter='searchAcademyPartnersBy($event, "partner")'
            >
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Auth
            <select
              class="form-select form-select-sm"
              data-filter-type="auth"
              @change="filterAcademyPartnersBy"
            >
              <option value="">
                -- All --
              </option>
              <option
                v-for='(auth, index) in auth_types'
                :key="index"
                :value="auth"
              >
                {{ auth }}
              </option>
            </select>
          </CTableHeaderCell>
          <CTableHeaderCell scope="col">
            Theme
            <select
              class="form-select form-select-sm"
              data-filter-type="theme"
              @change="filterAcademyPartnersBy"
            >
              <option value="">
                -- All --
              </option>
              <option
                v-for='(theme, index) in available_themes'
                :key="index"
                :value="theme"
              >
                {{ theme }}
              </option>
            </select>
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        <template v-for="(academy_partner, index) in academy_partners">
          <CTableRow
            v-for="(url, urlIndex) in urlCombinationDomains(academy_partner)"
            :key="`row-${index}-${urlIndex}`"
            @click.prevent="openModal(academy_partner)"
          >
            <CTableDataCell>
              <a
                :href="url"
                target="_blank"
                @click.stop="openUrl(academy_partner)"
              >
                {{ url }}
              </a>
            </CTableDataCell>
            <CTableDataCell>{{ academy_partner.academy }}</CTableDataCell>
            <CTableDataCell>{{ academy_partner.partner }}</CTableDataCell>
            <CTableDataCell>{{ academy_partner.auth }}</CTableDataCell>
            <CTableDataCell>
              {{ themeForItem(academy_partner) }}
            </CTableDataCell>
          </CTableRow>
        </template>
      </CTableBody>
    </CTable>

    <CPagination
      v-if='total_pages > 1'
      align='center'
    >
      <CPaginationItem
        v-for='(page, index) in pages'
        :key='index'
        href="#"
        :active='page_number == index + 1'
        @click.prevent='changePage(index)'
      >
        {{ page + 1 }}
      </CPaginationItem>
    </CPagination>
  </template>


  <CModal
    centered
    size="xl"
    :visible='is_ap_modal_opened'
    @close='closeModal'
  >
    <CModalBody>
      <AcademyPartnerModal
        v-if="current_academy_partner"
        :academy_partner="current_academy_partner"
        :api_client="api_client"
        :api_url='api_url'
        :admin_api_url='admin_api_url'
      />
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeModal"
      >
        Close
      </CButton>
    </CModalFooter>
  </CModal>
</div>
</template>

<script>
import {
  CAlert,
  CButton,
  CModal,
  CModalFooter,
  CModalBody,
  CSpinner,
  CPagination,
  CPaginationItem
} from '@coreui/vue'

import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody
} from '@coreui/vue'

import { CIcon } from '@coreui/icons-vue'
import AcademyPartnerModal from './AcademyPartnerModal.vue'
import { inject } from 'vue'

export default {
  props: ['api_client', 'api_url', 'admin_api_url'],

  components: {
    CAlert,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CIcon,
    CModal,
    CModalBody,
    CModalFooter,
    CSpinner,
    CPagination,
    CPaginationItem,
    AcademyPartnerModal
  },

  data() {
    return {
      columns: [{
        key: 'subdomain',
        label: 'URL'
      }, {
        key: 'academy',
        label: 'Academy'
      }, {
        key: 'partner',
        label: 'Partner'
      }, {
        key: 'auth',
        label: 'Auth'
      }, {
        key: 'theme_identifier',
        label: 'Theme'
      }],
      search_query: {},
      filter: {},
      page_number: 1,
      total_pages: 0,

      is_ap_modal_opened: false,

      academy_partners: [],
      is_academy_partners_loaded: false,
      current_academy_partner: null,

      auth_types: ['basic', 'saml', 'otp', 'omniauth', 'proxy_jwt'],
      available_themes: [
        'base-light',
        'base-medium',
        'base-dark',
        'betterfly',
        'carebridge',
        'covidvax',
        'light',
        'medela',
        'mindfull',
        'mobe',
        'nps',
        'phs',
        'phs-optum',
        'phs-uhc',
        'optumbank',
        'rally',
        'ula',
        'usbank',
        'walmart'
      ]
    }
  },

  created() {
    this.fetchAcademyPartners()
    this.icons = inject('icons')
  },

  computed: {
    is_data_loaded() {
      return this.is_academy_partners_loaded
    },

    pages() {
      return [...Array(this.total_pages).keys()]
    }
  },

  methods: {
    fetchAcademyPartners() {
      this.api_client.findAll('academy_partners', {
        page: {
          number: this.page_number
        },
        query: this.search_query,
        filter: this.filter
      }).then(
        function(data) {
          this.academy_partners = data.data
          this.is_academy_partners_loaded = true
          this.total_pages = data.meta.total_pages
        }.bind(this)
      )
    },

    changePage(index) {
      this.page_number = index + 1;
      this.fetchAcademyPartners()
    },

    searchAcademyPartnersBy(e, param) {
      // reject empty string params
      // if (!e.target.value.length) return false
      const params = {
        [param]: e.target.value
      }
      this.search_query = params
      this.page_number = 1
      this.fetchAcademyPartners()
    },

    filterAcademyPartnersBy(e) {
      if (value === null || value === '') {
        delete this.filter[filter_key]
      } else {
        this.filter[filter_key] = value
      }
      this.page_number = 1
      this.fetchAcademyPartners()
    },

    openModal(academy_partner) {
      this.current_academy_partner = academy_partner
      this.is_ap_modal_opened = true
    },

    closeModal() {
      if (this.is_ap_modal_opened) { this.is_ap_modal_opened = false }
      this.current_academy_partner = null
    },

    openUrl(item) {
      return false
    },

    urlCombinationDomains(item) {
      return item.fqdn_combinations
    },

    themeForItem(item) {
      let has_theming = false
      if (item.theming && Object.keys(item.theming).filter((k) => item.theming[k] !== null).length) {
        has_theming = true
      }

      return item.theme_identifier + (has_theming ? ', modified' : '')
    }
  }
}
</script>

<style lang='sass'>
td
  padding: 10px
  font-size: 14px

  a
    color: #3c4b64
    text-decoration: none
    &:hover
      text-decoration: underline
</style>
