<template>
  <CModal
    alignment='center'
    :visible="opened"
    @close="closeCb"
  >
    <CModalBody>
      <slot />
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeCb"
      >
        Close
      </CButton>

      <CButton
        color="primary"
        @click="confirmCb"
      >
        Confirm
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/vue'

export default {
  components: {
    CModal,
    CModalBody,
    CModalFooter,
    CButton
  },

  props: ['opened', 'closeCb', 'confirmCb']
}
</script>
