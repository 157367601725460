<template>
<VeeForm
  v-slot="{ errors, meta }"
  :validateOnInput='true'
  :validateOnChange='true'
>
  <span class='ada-hidden'>{{ validateForm(meta) }}</span>
  <CRow>
    <CCol
      md="4"
      class="text-center left-panel"
    >
      <CImage
        src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
        alt="profile-image"
        class="mb-3 rounded-circle img-thumbnail"
      />
      <h4>{{ user.first_name }} {{ user.last_name }}</h4>

      <CFormSwitch
        v-model="user.enabled"
        name="enabled"
        type="checkbox"
        color="success"
        size='xl'
      />
    </CCol>

    <CCol md="8">
      <CRow class="mb-3">
        <CFormLabel
          for="id"
          class="col-sm-2 col-form-label"
        >
          ID
        </CFormLabel>

        <div class="col-sm-10">
          <CFormInput
            v-if="persisted"
            v-model='user.id'
            :plain-text='true'
            readonly
            name="id"
          />
        </div>
      </CRow>

      <CRow class="mb-3">
        <CFormLabel
          for="first_name"
          class="col-sm-2 col-form-label"
        >
          First Name
        </CFormLabel>

        <div class="col-sm-10">
          <CFormInput
            v-model='user.first_name'
            name="first_name"
          />
        </div>
      </CRow>

      <CRow class="mb-3">
        <CFormLabel
          for="last_name"
          class="col-sm-2 col-form-label"
        >
          Last Name
        </CFormLabel>

        <div class="col-sm-10">
          <CFormInput
            v-model='user.last_name'
            name="last_name"
          />
        </div>
      </CRow>

      <CRow class="mb-3">
        <CFormLabel
          for="email"
          class="col-sm-2 col-form-label"
        >
          Email
        </CFormLabel>

        <div class="col-sm-10">
          <Field
            v-slot="{ field, meta }"
            v-model='user.email'
            rules='required|email'
            name='email'
          >
            <input
              id='email'
              type='text'
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
              aria-describedby="err_email"
            >
            <span
              v-if="errors.email"
              id='err_email'
              class='invalid-feedback'
            >
              The email field must be a valid email
            </span>
          </Field>
        </div>
      </CRow>

      <CRow class="mb-3">
        <CFormLabel
          for="password"
          class="col-sm-2 col-form-label"
        >
          Password
        </CFormLabel>

        <div class="col-sm-10">
          <Field
            v-slot="{ field, meta }"
            v-model='user.password'
            :rules='password_validation_rules'
            name='password'
          >
            <input
              id='password'
              autocomplete="new-password"
              type='password'
              v-bind='field'
              class='form-control'
              :class="validationClass(meta)"
              aria-describedby="err_pwd"
              @change='onPasswordChange'
            >
            <span
              v-if="errors.password"
              id='err_pwd'
              class='invalid-feedback'
            >
              The password field must be at least 8 characters
            </span>
          </Field>
        </div>
      </CRow>

      <CRow class="mb-3">
        <CFormLabel
          for="roles"
          class="col-sm-2 col-form-label"
        >
          Roles
        </CFormLabel>

        <div class="col-sm-10">
          <template v-if="self">
            {{ role_list }}
          </template>

          <vue-select
            v-else
            v-model="user.roles"
            multiple
            :options="roles"
            label="name"
          />
        </div>
      </CRow>
    </CCol>
  </CRow>
</VeeForm>
</template>

<script>
import {
  CCol,
  CImage,
  CLink,
  CRow,
  CFormInput,
  CFormSwitch,
  CFormLabel
} from '@coreui/vue'

import { CIcon } from '@coreui/icons-vue'

import { inject } from 'vue'

import VueSelect from "vue-select"
import 'vue-select/dist/vue-select.css'

import { Form as VeeForm, Field } from 'vee-validate'
import { defineRule } from 'vee-validate'
import {
  required,
  email
} from '@vee-validate/rules'

export default {
  props: [
    'api_client',
    'user',
    'self',
    'roles'
  ],

  emits: [
    'update-validity'
  ],

  components: {
    CCol,
    CImage,
    CRow,
    CFormInput,
    CFormSwitch,
    CFormLabel,
    VueSelect,
    VeeForm,
    Field
  },

  watch: {
    'form_valid': function(value) {
      this.$emit('update-validity', value)
    }
  },

  data() {
    return {
      password_field_type: 'password',
      form_valid: true
    }
  },

  created() {
    this.icons = inject('icons')

    // validation rules
    defineRule('required', required);
    defineRule('email', email);
    defineRule('password', (value) => {
      if (value && value !== '') {
        return value.length >= 8
      }
      return true
    })
  },

  mounted() {
    this.$emit('update-validity', this.form_valid)
  },

  computed: {
    password_validation_rules() {
      return this.persisted ? 'password' : 'required|password'
    },

    persisted() {
      return !!this.user.id
    },

    role_list() {
      return this.user.roles && this.user.roles.map(r => r.name).join(', ')
    }
  },

  methods: {
    tooglePasswordVisibility() {
      this.password_field_type = this.password_field_type === 'password' ? 'text' : 'password'
    },

    onPasswordChange({ target }) {
      if (this._isEmpty(target.value)) {
        delete this.user['password']
      }
    },

    validationClass(meta) {
      return {
        'is-invalid': meta.dirty && meta.validated && !meta.valid
      }
    },

    _isEmpty(value) {
      return value === null || value === undefined || value === ''
    },

    validateForm(meta) {
      this.form_valid = meta.valid
    }
  }
}
</script>
<style lang='sass' scoped>
.left-panel :deep
  .form-check.form-switch
    .form-check-input
      float: none

.invalid-feedback
  display: block
</style>
