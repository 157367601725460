<template>
<div>
  <CCard
    v-for='lesson in lessons'
    :key='lesson.identifier'
    accent-color="secondary"
    class='mb-2 border-light'
  >
    <CCardHeader>
      <div
        class="row"
        style='alignItems: center'
      >
        <div
          class="col-md-9 lesson-title"
          @click='toggleLesson(lesson)'
        >
          {{ lesson.title }}
        </div>

        <div class="col-md-1">
          <button
            v-if='is_admin_root'
            type="button"
            class="btn btn-sm btn-ghost-primary"
            @click.prevent='resetClick(lesson)'
          >
            Reset
          </button>
        </div>

        <div class="col-md-2 text-right">
          <CBadge
            v-if="lesson.completion_date"
            color="success"
            size='sm'
          >
            <div class="mt-3 course-info-badge">
              <div class="mt-3 course-info-badge_header">
                <p>Completed on: </p>
              </div>
                <p>{{ formatted_completion_date(lesson) }}</p>
            </div>
          </CBadge>

          <CBadge
            v-else-if='lesson.enrollment_date'
            color="info"
            size='sm'
          >
            <div class="mt-3 course-info-badge">
              <div class="mt-3 course-info-badge_header">
                <p>In Progress</p>
              </div>
                <p>Enrolled On:</p>
                <p>{{ formatted_enrollment_date(lesson) }}</p>
            </div>
          </CBadge>
        </div>
      </div>
    </CCardHeader>

    <CCollapse :visible="!!lesson_collapse_state[lesson.id]">
      <CCardBody>
        <h6 class="col-12">
          Slides
        </h6>
        <Slides
          :slides="lesson.slides"
          :resetProgress='resetProgress'
          :completeProgress='completeProgress'
          :is_admin_root='is_admin_root'
        />
      </CCardBody>
    </CCollapse>
  </CCard>

  <CModal
    :visible='show_reset_confirm'
    alignment='center'
    @close='closeResetModal'
  >
    <CModalHeader>
      <h5 class='modal-title'>
        Reset {{ lesson_to_reset?.title }}
      </h5>
    </CModalHeader>

    <CModalBody>
      The <strong>{{ lesson_to_reset?.title }}</strong> progress will be reset. This will also reset the activity result.
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeResetModal"
      >
        Cancel
      </CButton>

      <CButton
        color="primary"
        @click="resetLessonProgress"
      >
        Confirm Reset
      </CButton>
    </CModalFooter>
  </CModal>
</div>
</template>

<script>
import { format } from 'date-fns'
import {
  CBadge,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse
} from '@coreui/vue'
import Slides from './EnrollmentsSlides'

export default {
  props: ['lessons', 'resetProgress', 'completeProgress', 'is_admin_root'],

  components: {
    CBadge,
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CCard,
    CCardHeader,
    CCardBody,
    CCollapse,
    Slides
  },

  data() {
    return {
      show_reset_confirm: false,
      lesson_to_reset: null,
      lesson_collapse_state: {}
    }
  },

  methods: {
    formatted_enrollment_date(lesson) {
      return format(new Date(lesson.enrollment_date), 'MM/dd/yyyy')
    },

    formatted_completion_date(lesson) {
      return format(new Date(lesson.completion_date), 'MM/dd/yyyy')
    },

    closeResetModal() {
      this.show_reset_confirm = false
      this.lesson_to_reset = null
    },

    resetClick(lesson) {
      this.show_reset_confirm = true
      this.lesson_to_reset = lesson
    },

    resetLessonProgress() {
      this.resetProgress('lesson', this.lesson_to_reset.id)
      this.closeResetModal()
    },

    toggleLesson(lesson) {
      this.lesson_collapse_state[lesson.id] = !this.lesson_collapse_state[lesson.id]
    }
  }
}
</script>

<style lang='sass' scoped>
.lesson-title:hover
  text-decoration: underline
  cursor: pointer

.text-right
  text-align: right
</style>
