<template>
<div>
  <CAlert
    color="primary"
    dismissible
  >
    <div class="d-inline-block mr-3 align-top">
      <CIcon :icon="icons.cilWarning" />
    </div>
    <div class="d-inline-block">
      Search is performed when you press Enter.
    </div>
  </CAlert>

  <CTable
    hover
    striped
    bordered
    align="middle"
  >
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col">
          Identifier
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "identifier")'
          >
        </CTableHeaderCell>
        <CTableHeaderCell scope="col">
          Email
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "email")'
          >
        </CTableHeaderCell>
        <CTableHeaderCell scope="col">
          First Name
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "first_name")'
          >
        </CTableHeaderCell>
        <CTableHeaderCell scope="col">
          Last Name
          <input
            class="form-control form-control-sm"
            @keyup.enter='searchUsersBy($event, "last_name")'
          >
        </CTableHeaderCell>
        <CTableHeaderCell scope="col">
          Subdomain
          <select
            class="form-select form-select-sm"
            data-filter-type="academy_partner"
            @change="filterUsersBy"
          >
            <option value="">
              -- All --
            </option>
            <option
              v-for='(ap, index) in academy_partners'
              :key="index"
              :value="ap.id"
            >
              {{ ap.subdomain }}
            </option>
          </select>
        </CTableHeaderCell>
        <!-- delete -->
        <CTableHeaderCell
          v-if="is_admin_root"
          scope="col"
          style='width: 4%'
        />
      </CTableRow>
    </CTableHead>

    <CTableBody>
      <CTableRow
        v-for='(user, index) in users'
        :key='index'
        @click.prevent='openModal(user)'
      >
        <CTableDataCell> {{ user.identifier }} </CTableDataCell>
        <CTableDataCell> {{ user.email }} </CTableDataCell>
        <CTableDataCell> {{ user.first_name }} </CTableDataCell>
        <CTableDataCell> {{ user.last_name }} </CTableDataCell>
        <CTableDataCell> {{ user.subdomain }} </CTableDataCell>
        <CTableDataCell v-if="is_admin_root">
          <CButton
            size="sm"
            @click.stop="confirmUserDelete(user)"
          >
            <CIcon :content="icons.cilTrash" />
          </CButton>
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>

  <CPagination
    v-if='total_pages > 1'
    align='center'
  >
    <!-- First page -->
    <CPaginationItem
      :disabled='page_number === 0'
      href="#"
      @click.prevent='changePage(0)'
    >
      &lt;&lt;
    </CPaginationItem>

    <!-- Previous Page -->
    <CPaginationItem
      :disabled='page_number === 0'
      href="#"
      @click.prevent='changePage(page_number - 1)'
    >
      &lt;
    </CPaginationItem>

    <!-- first gap -->
    <CPaginationItem
      v-if='first_gap_condition'
      :disabled='first_gap_condition'
    >
      ...
    </CPaginationItem>


    <CPaginationItem
      v-for='(page, index) in pages'
      :key='index'
      href="#"
      :active='page_number == page'
      @click.prevent='changePage(page)'
    >
      {{ page + 1 }}
    </CPaginationItem>

    <!-- last gap -->
    <CPaginationItem
      v-if='last_gap_condition'
      :disabled='last_gap_condition'
    >
      ...
    </CPaginationItem>

    <!-- Next Page -->
    <CPaginationItem
      :disabled='page_number === total_pages - 1'
      href="#"
      @click.prevent='changePage(page_number + 1)'
    >
      &gt;
    </CPaginationItem>

    <!-- Last page -->
    <CPaginationItem
      :disabled='page_number === total_pages -1'
      href="#"
      @click.prevent='changePage(total_pages - 1)'
    >
      &gt;&gt;
    </CPaginationItem>
  </CPagination>

  <CModal
    centered
    alignment='center'
    size="xl"
    :visible='is_user_modal_opened'
    @close="closeModal"
  >
    <UserModal
      v-if="current_user"
      :user="current_user"
      :api_client="api_client"
      :is_admin_root='is_admin_root'
    />

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeModal"
      >
        Close
      </CButton>
    </CModalFooter>
  </CModal>

  <DeleteConfirmationModal
    :opened="delete_modal_opened"
    :confirmCb='confirmDelete'
    :closeCb='closeDeleteModal'
  >
    <div
      v-if="current_user"
      class="text-center"
    >
      Are you sure you want to delete <span class="font-weight-bold">{{ current_user.email }}</span>
      <br>from <strong>{{ current_user.subdomain }}</strong> subdomain?
      This will delete the user’s record and all of their associated data.
    </div>
  </DeleteConfirmationModal>
</div>
</template>

<script>
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody
} from '@coreui/vue'

import {
  CAlert,
  CButton,
  CModal,
  CModalFooter,
  CPagination,
  CPaginationItem
} from '@coreui/vue'

import { CIcon } from '@coreui/icons-vue'
import UserModal from './UserModal/UserModal'
import DeleteConfirmationModal from '../Shared/DeleteConfirmationModal/DeleteConfirmationModal'
import { inject } from 'vue'

export default {
  props: [
    'api_client',
    'is_admin_root'
  ],

  components: {
    CAlert,
    CButton,
    CIcon,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
    CTableBody,
    CModal,
    CModalFooter,
    CPagination,
    CPaginationItem,
    UserModal,
    DeleteConfirmationModal
  },

  data() {
    return {
      users: [],
      search_query: {},
      filter: {},
      page_number: 0,
      total_pages: 0,
      max_pages: 8,
      is_users_loaded: false,
      current_user: null,
      is_user_modal_opened: false,
      delete_modal_opened: false,
      academy_partners: [],
      is_academy_partners_loaded: false
    }
  },

  created() {
    this.icons = inject('icons')
    this.fetchAcademyPartners()
    this.fetchUsers()
  },

  computed: {
    current_user_index() {
      if (this.current_user) {
        return this.users.indexOf(this.current_user)
      } else {
        return null
      }
    },

    is_data_loaded() {
      return this.is_academy_partners_loaded && this.is_users_loaded
    },

    pages() {
      const pages = []

      for (let i = 0; i < this.total_pages; i++) {
        pages.push(i)
      }

      const filtered_pages = pages.filter((page) => {
        if (this.page_number < Math.floor(this.max_pages / 2) + 1) {
          return page < this.max_pages + 1
        } else {
          if (this.page_number < this.total_pages - Math.floor(this.max_pages / 2) + 1) {
            return page > this.page_number - Math.floor(this.max_pages / 2) && page <= this.page_number + Math.floor(this.max_pages / 2) - 1
          } else {
            return page > this.total_pages - this.max_pages
          }
        }
      })

      return this.total_pages <= this.max_pages ? pages : filtered_pages
    },

    first_gap_condition() {
      return this.total_pages > this.max_pages && this.page_number >= Math.floor(this.max_pages / 2) + 1
    },

    last_gap_condition() {
      return this.total_pages > this.max_pages && this.page_number < this.total_pages - Math.floor(this.max_pages / 2)
    }
  },

  methods: {
    fetchAcademyPartners() {
      this.api_client.findAll('academy_partners').then(
        function({ data }) {
          this.academy_partners = data
          this.is_academy_partners_loaded = true
        }.bind(this)
      )
    },

    fetchUsers() {
      this.is_users_loaded = false
      this.api_client.findAll('users', {
        page: {
          number: this.page_number + 1
        },
        query: this.search_query,
        filter: this.filter
      }).then(
        function(data) {
          this.users = data.data
          this.total_pages = data.meta.total_pages
          this.is_users_loaded = true
        }.bind(this)
      )
    },

    searchUsersBy(e, param) {
      // reject empty string params
      // Object.keys(params).forEach((k) => { (params[k].length === 0) && delete params[k] })
      const params = {
        [param]: e.target.value
      }
      this.search_query = params
      this.page_number = 0
      this.fetchUsers()
    },

    filterUsersBy(e) {
      const filter_key = e.target.dataset.filterType
      const value = e.target.value
      if (value === null || value === '') {
        delete this.filter[filter_key]
      } else {
        this.filter[filter_key] = value
      }
      this.page_number = 0
      this.fetchUsers()
    },

    changePage(index) {
      this.page_number = index;
      this.fetchUsers()
    },

    openModal(user) {
      this.current_user = user
      this.is_user_modal_opened = true
    },

    closeModal() {
      if (this.is_user_modal_opened) { this.is_user_modal_opened = false }
      this.current_user = null
    },

    confirmUserDelete(user) {
      this.current_user = user
      this.delete_modal_opened = true
    },

    confirmDelete() {
      this.api_client.destroy('users', this.current_user.id)
        .then(() => {
          this.users.splice(this.current_user_index, 1)
        })
        .catch((_error) => {
          Tbk.alerts.new('Sorry, but something went wrong', 'error')
        })
        .finally(() => {
          this.current_user = null
        })
      this.delete_modal_opened = false
    },

    closeDeleteModal() {
      this.current_user = null
      this.delete_modal_opened = false
    }

  }
}
</script>

<style lang='sass'>
@import './Users'
</style>
