import AcademyPartner from './academy_partner'
import AdminRole from './admin_role'
import AdminUser from './admin_user'
import ReportsConfiguration from './reports_configuration'
import SamlConfiguration from './saml_configuration'

export default {
  load: function(apiClient) {
    apiClient.define('academy_partner', AcademyPartner)
    apiClient.define('admin_role', AdminRole)
    apiClient.define('admin_user', AdminUser)
    apiClient.define('reports_configuration', ReportsConfiguration, { collectionPath: 'reports_configuration' })
    apiClient.define('saml_configuration', SamlConfiguration)
  }
}
