<template>
<div>
  <CCard
    v-for='(course, index) in enrollments'
    :key='index'
    accent-color="secondary"
    class='mb-2 border-light'
  >
    <CCardHeader>
      <div
        class="row"
        style='alignItems: center'
      >
        <div
          class="col-md-9 course-title"
          @click='toggleCourse(course)'
        >
          {{ course.title }}
        </div>

        <div class="col-md-1">
          <button
            v-if='is_admin_root'
            type="button"
            class="btn btn-sm btn-ghost-primary"
            @click.prevent='resetClick(course)'
          >
            Reset
          </button>
        </div>

        <div class="col-md-2 text-right">
          <CBadge
            v-if="course.completion_date"
            color="success"
            size='sm'
          >
            <div class="mt-3 course-info-badge">
              <div class="mt-3 course-info-badge_header">
                <p>Completed on: </p>
              </div>
                <p>{{ formatted_completion_date(course) }}</p>
            </div>
          </CBadge>

          <CBadge
            v-else-if="course.enrollment_date"
            color="info"
            size='sm'
          >
            <div class="mt-3 course-info-badge">
              <div class="mt-3 course-info-badge_header">
                <p>In Progress</p>
              </div>
                <p>Enrolled On:</p>
                <p>{{ formatted_enrollment_date(course) }}</p>
            </div>
          </CBadge>
        </div>
      </div>
    </CCardHeader>

    <CCollapse :visible="!!course_collapse_state[course.id]">
      <CCardBody>
        <h6 class="col-12">
          Lessons
        </h6>
        <Lessons
          :lessons="course.lessons"
          :resetProgress='resetProgress'
          :completeProgress='completeProgress'
          :is_admin_root='is_admin_root'
        />
      </CCardBody>
    </CCollapse>
  </CCard>

  <!-- Confirm Modals -->
  <!-- Confirm Reset -->
  <CModal
    alignment='center'
    :visible='show_reset_confirm'
    @close='closeResetModal'
  >
    <CModalHeader>
      <h5 class='modal-title'>
        Reset {{ course_to_reset?.title }}
      </h5>
    </CModalHeader>

    <CModalBody>
      The <strong>{{ course_to_reset?.title }}</strong> progress will be reset. This will also reset the activity result.
    </CModalBody>

    <CModalFooter>
      <CButton
        color="secondary"
        @click="closeResetModal"
      >
        Cancel
      </CButton>

      <CButton
        color="primary"
        @click.prevent="resetCourseProgress"
      >
        Confirm Reset
      </CButton>
    </CModalFooter>
  </CModal>
</div>
</template>

<script>
import { format } from 'date-fns'
import {
  CBadge,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse
} from '@coreui/vue'
import Lessons from './EnrollmentsLessons'

export default {
  props: ['enrollments', 'resetProgress', 'completeProgress', 'is_admin_root'],

  components: {
    CBadge,
    CCard,
    CCardHeader,
    CCardBody,
    CCollapse,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    Lessons
  },

  data() {
    return {
      show_reset_confirm: false,
      course_to_reset: null,
      course_collapse_state: {}
    }
  },

  methods: {
    formatted_enrollment_date(course) {
      return format(new Date(course.enrollment_date), 'MM/dd/yyyy')
    },

    formatted_completion_date(course) {
      return format(new Date(course.completion_date), 'MM/dd/yyyy')
    },

    closeResetModal() {
      this.show_reset_confirm = false
      this.course_to_reset = null
    },

    resetClick(course) {
      this.show_reset_confirm = true
      this.course_to_reset = course
    },

    resetCourseProgress() {
      this.resetProgress('course', this.course_to_reset.id)
      this.closeResetModal()
    },

    toggleCourse(course) {
      this.course_collapse_state[course.id] = !this.course_collapse_state[course.id]
    }
  }
}
</script>

<style lang='sass' scoped>
.course-title:hover
  text-decoration: underline
  cursor: pointer

.text-right
  text-align: right
</style>
